import { useContext, useState, useEffect } from "react";
import TextField from "@mui/material/TextField"; 
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AuthService from "services/auth-service";
import { AuthContext } from "context";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import Autocomplete from "@mui/material/Autocomplete";
import SecureLS from 'secure-ls';
import useStore from "services/store";
// Initialiser SecureLS (avec AES comme algorithme de chiffrement par défaut)
const secureLocalStorage = new SecureLS({ encodingType: 'aes' });

function Login() {
  
    console.log("Store after logout:", useStore.getState());
 

  const authContext = useContext(AuthContext);
  const [rememberMe, setRememberMe] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
    credentialsErros: false,
    textError: "",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
  
    // Créer un nouvel utilisateur unique
    const newUser = {
      email: inputs.email,
      password: inputs.password,
    };

    
    try {
      const response = await AuthService.login(newUser);
      
      if (response.access_token) {
        secureLocalStorage.set("IdentiteSls", {   
          email: inputs.email,
        });
        console.log("ivg : ", response)
        authContext.login(response.access_token, response.identifiant);
      } else {
        throw new Error("Invalid login response");
      }
    } catch (error) {
      setErrors({ ...errors, credentialsErros: true, textError: error.message });
    }
  };
  
  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography color="dark" fontWeight="light" variant="body2" mb={1}>
            You can sign in with 3 user types:
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            name="email"
            value={inputs.email}
            onChange={changeHandler}
            error={errors.emailError}
            required
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            name="password"
            value={inputs.password}
            onChange={changeHandler}
            error={errors.passwordError}
            required
          />
        </MDBox>
        {/* <MDBox mb={2}>
          <Autocomplete
            options={["administrateur", "professeur", "gestionnaire", "élève", "parent"]}
            getOptionLabel={(option) => option}
            onChange={handleRoleChange} 
            renderInput={(params) => <TextField {...params} label="Select Role" required />}
          />
        </MDBox>
        {tempRole && tempRole !== "administrateur" && (
          <MDBox mb={2}>
            <Autocomplete
              options={etabs}
              getOptionLabel={(option) => option.denomination_etablissement || "Unknown"} 
              onChange={handleEtabChange} 
              renderInput={(params) => <TextField {...params} label="Select Etab" required />}
            />
          </MDBox>
        )} */}
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        {errors.credentialsErros && (
          <MDTypography variant="caption" color="error" fontWeight="light">
            {errors.textError}
          </MDTypography>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth type="submit">
            sign in
          </MDButton>
        </MDBox>
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Forgot your password? Reset it{" "}
            <MDTypography
              component={Link}
              to="/auth/forgot-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              here
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Login;
