// useStore.js

import create from 'zustand';
import { persist } from 'zustand/middleware';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

const useStore = create(
  persist(
    (set) => ({
      selectedEtabManagement: null,
      setSelectedEtabManagement: (etab) => set({ selectedEtabManagement: etab }),

      selectedEtab: null,
      setSelectedEtab: (etab) => set({ selectedEtab: etab }),

      selectedSection: null,
      setSelectedSection: (section) => set({ selectedSection: section }),

      selectedPromotion: null,
      setSelectedPromotion: (promotion) => set({ selectedPromotion: promotion }),

      selectedMatiere: null,
      setSelectedMatiere: (matiere) => set({ selectedMatiere: matiere }),

      userId: null,
      setUserId: (id) => set({ userId: id }),

      userIdentite: null,
      setUserIdentite: (identite) => set({ userIdentite: identite }),

      role: null,
      setRole: (newRole) => set({ role: newRole }),

      selectedReferentiel: null,
      setSelectedReferentiel: (referentiel) => set({ selectedReferentiel: referentiel }),

      reset: () => set({
        selectedEtabManagement: null,
        selectedEtab: null,
        selectedSection: null,
        selectedPromotion: null,
        selectedMatiere: null,
        userId: null,
        userIdentite: null,
        role: null,
        selectedReferentiel: null,
      }),
    }),
    {
      name: 'user-store',
      getStorage: () => ({
        getItem: (key) => {
          try {
            return ls.get(key);
          } catch (error) {
            console.error("Erreur lors du décryptage des données : ", error);
            return null;
          }
        },
        setItem: (key, value) => {
          try {
            ls.set(key, value);
          } catch (error) {
            console.error("Erreur lors du chiffrement des données : ", error);
          }
        },
        removeItem: (key) => {
          ls.remove(key);
        },
      }),
    }
  )
);

export default useStore;
