import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { AbilityContext } from "Can";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "context";

function ParcoursCard({ title, description, identifiant_parcours, idMatiere }) {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isImageReady, setIsImageReady] = useState(false); // State to track if the image is ready
  const ability = useContext(AbilityContext); 
  const navigate = useNavigate();

  const canEditActivity = ability.can("edit", "activite", idMatiere);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const timestamp = new Date().getTime();
        const url = `${process.env.REACT_APP_FAPI_URL}/files/parcours/${identifiant_parcours}.jpg?t=${timestamp}`;
        setImageUrl(url);
        setIsImageReady(true);
      } catch (error) {
        console.error("Erreur lors du chargement de l'image :", error);
      }
    };
    loadImage();
  }, [identifiant_parcours]);

  const handleImageError = () => {
    setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/parcours/defaut.jpg`);
    setIsImageReady(true);
  };

  const handleNavigate = () => {
    navigate(`/parcours/parcours-espace/parcours-page/${identifiant_parcours}`);
  };

  const handleEdit = () => {
    navigate(`/parcours/parcours-gestion/parcours-nouveau?identifiant_parcours=${identifiant_parcours}&matiere=${idMatiere}`);
  };

  const handleDelete = async () => {
    try {
      await crudsServiceFAPI.deleteParcours(identifiant_parcours);
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression du parcours :", error);
      alert("Erreur lors de la suppression du parcours");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setIsImageReady(true);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      const newFileName = `${identifiant_parcours}.jpg`;
      formData.append("file", new File([selectedFile], newFileName));

      try {
        const path = `parcours`;
        await crudsServiceFAPI.uploadFile(`${path}/`, formData);

        const timestamp = new Date().getTime();
        setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/parcours/${identifiant_parcours}.jpg?t=${timestamp}`);
        setIsImageReady(true);
        window.location.reload();
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  if (!isImageReady) {
    return <div>Chargement de l'image...</div>;
  }

  return (
    <>
      <Card sx={{ transition: "all 0.3s ease", maxWidth: "100%", height: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <MDBox position="relative" borderRadius="lg" mt={2} mx={2}>
          <MDBox component="img" src={imageUrl} alt={title} borderRadius="lg" shadow="md" width="100%" height="auto" zIndex={1} onError={handleImageError} />
          {ability.can("edit", "parcours", idMatiere) && (
            <MDBox position="absolute" top={8} right={8} zIndex={2} display="flex" gap={1}>
              <IconButton component="label">
                <PhotoCameraIcon fontSize="large" />
                <input type="file" accept="image/*" onChange={handleFileChange} hidden />
              </IconButton>
              {selectedFile && (
                <IconButton onClick={handleUpload}>
                  <SaveIcon fontSize="large" />
                </IconButton>
              )}
            </MDBox>
          )}
        </MDBox>

        <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
          <MDTypography variant="h5" fontWeight="regular">{title}</MDTypography>
          <MDTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: description }} />
          <MDTypography variant="caption" color="text">Identifiant: {identifiant_parcours}</MDTypography>
        </MDBox>

        <Divider />

        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} pb={3} px={2}>
          {ability.can("view", "parcours", idMatiere) && (
            <MDButton variant="text" color="info" onClick={handleNavigate}>
              <VisibilityIcon fontSize="large" />
            </MDButton>
          )}
          {ability.can("edit", "parcours", idMatiere) && (
            <MDButton variant="text" color="warning" onClick={handleEdit}>
              <EditIcon fontSize="large" />
            </MDButton>
          )}
          {ability.can("delete", "parcours", idMatiere) && (
            <>
              <MDButton variant="text" color="error" onClick={handleClickOpen}>
                <DeleteIcon fontSize="large" />
              </MDButton>

              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Confirmation de suppression"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Êtes-vous sûr de vouloir supprimer ce parcours ? Cette action est irréversible.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleClose} color="primary">Annuler</MDButton>
                  <MDButton onClick={handleDelete} color="error" autoFocus>Supprimer</MDButton>
                </DialogActions>
              </Dialog>
            </>
          )}
        </MDBox>
      </Card>
    </>
  );
}

ParcoursCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  identifiant_parcours: PropTypes.number,
  idMatiere: PropTypes.string,
};

export default ParcoursCard;
