import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import { useMaterialUIController } from "context";

function MDEditor(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const quillRef = useRef(null); // Référence pour accéder à l'instance de Quill si nécessaire

  // Configuration des modules
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }], // Ajout des options de couleur
      ['link', 'image'],
      ['clean']
    ],
  };

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <ReactQuill
        ref={quillRef} // Utilisation de la référence
        theme="snow"
        modules={modules}
        {...props}
      />
    </MDEditorRoot>
  );
}

export default MDEditor;
