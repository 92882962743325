// CreateTicketPage.jsx
import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Card,
  Grid,
  TextField,
  MenuItem,
  Alert,
  Divider,
  Typography,
} from "@mui/material";
import { orange, green, red } from "@mui/material/colors"; // Import des couleurs
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SendIcon from "@mui/icons-material/Send";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AuthContext } from "context";
import DataTable from "examples/Tables/DataTable"; // Import du DataTable

// Fonction utilitaire pour obtenir la couleur en fonction du statut
const getStatusColor = (status) => {
  switch (status) {
    case 'ouvert':
      return orange[500];
    case 'résolu':
      return green[500];
    case 'fermé':
      return red[500];
    default:
      return 'grey'; // Couleur par défaut si le statut n'est pas reconnu
  }
};

function CreateTicketPage() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);

  // État pour stocker les données du formulaire sans status_ticket
  const [formData, setFormData] = useState({
    titre_ticket: "",
    description_ticket: "",
    priorite_ticket: "",
    categorie_ticket: "",
    // status_ticket est supprimé
  });

  // État pour gérer les messages de succès et d'erreur
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // État pour stocker la liste des tickets
  const [tickets, setTickets] = useState([]);
  const isSuperAdmin = role === "superadministrateur";

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validation du formulaire avant soumission
  const validateForm = () => {
    const { titre_ticket, description_ticket, priorite_ticket, categorie_ticket } = formData;
    if (!titre_ticket || !description_ticket || !priorite_ticket || !categorie_ticket) {
      setError("Veuillez remplir tous les champs obligatoires.");
      return false;
    }
    setError("");
    return true;
  };

  // Fonction pour récupérer tous les tickets
  const fetchTickets = async () => {
    console.log("role : ", role)
    if(role === "superadministrateur"){
    try {
      const allTickets = await crudsServiceFAPI.getAllTickets();
      console.log("Tickets récupérés : ", allTickets);
      setTickets(allTickets || []); // Assurez-vous que tickets est toujours un tableau
    } catch (err) {
      console.error(err);
      setError("Erreur lors de la récupération des tickets.");
      setTickets([]); // Assurez-vous que tickets est toujours un tableau même en cas d'erreur
    }}else if (role === "administrateur" || role === "gestionnaire" || role === "professeur"){
      try {
        const allTickets = await crudsServiceFAPI.getTicketByUserId(userUid);
        console.log("Tickets récupérés : ", allTickets);
        setTickets(allTickets || []); // Assurez-vous que tickets est toujours un tableau
      } catch (err) {
        console.error(err);
        setError("Erreur lors de la récupération des tickets.");
        setTickets([]); // Assurez-vous que tickets est toujours un tableau même en cas d'erreur
      }
    }else{
      return ([])
    }

  };

  // Récupérer les tickets lors du montage du composant
  useEffect(() => {
    fetchTickets();
  }, []);

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setSuccess("");
      setError("");
      // Ajout de status_ticket par défaut si nécessaire, sinon le backend doit gérer la valeur par défaut
      const newTicket = await crudsServiceFAPI.createTicket(formData, userUid || "");
      setSuccess("Ticket créé avec succès !");
      // Réinitialiser le formulaire
      setFormData({
        titre_ticket: "",
        description_ticket: "",
        priorite_ticket: "",
        categorie_ticket: "",
        // status_ticket est supprimé
      });
      // Actualiser la liste des tickets
      fetchTickets();
    } catch (err) {
      setError(err.detail || err.message || "Erreur lors de la création du ticket. Veuillez réessayer.");
      console.error(err);
    }
  };

  // Définition des priorités et catégories disponibles
  const priorities = [
    { value: "faible", label: "Faible" },
    { value: "moyenne", label: "Moyenne" },
    { value: "haute", label: "Haute" },
  ];

  const categories = [
    { value: "bug", label: "Bug" },
    { value: "demande_de_fonctionnalite", label: "Demande de Fonctionnalité" },
    { value: "support", label: "Support" },
    { value: "autre", label: "Autre" },
  ];

  // Définition des colonnes pour DataTable
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id_ticket",
      },
      {
        Header: "Titre",
        accessor: "titre_ticket",
      },
      {
        Header: "Description",
        accessor: "description_ticket",
      },
      {
        Header: "Priorité",
        accessor: "priorite_ticket",
      },
      {
        Header: "Catégorie",
        accessor: "categorie_ticket",
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value, row }) => {
          const statusColor = getStatusColor(value);

          return (
            <TextField
              select
              value={value}
              onChange={async (e) => {
                const newStatus = e.target.value;
                try {
                  await crudsServiceFAPI.updateTicketStatus(row.original.id_ticket, newStatus);
                  setSuccess("Statut mis à jour avec succès !");
                  // Actualiser la liste des tickets
                  fetchTickets();
                } catch (err) {
                  setError(err.detail || err.message || "Erreur lors de la mise à jour du statut.");
                  console.error(err);
                }
              }}
              variant="outlined"
              size="small"
              disabled={!isSuperAdmin} // Désactive le TextField si non superadmin
              sx={{
                backgroundColor: `${statusColor}20`, // Couleur de fond avec transparence
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: statusColor,
                  },
                  "&:hover fieldset": {
                    borderColor: statusColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: statusColor,
                  },
                },
                "& .MuiInputBase-input": {
                  color: statusColor, // Couleur du texte
                },
                "& .MuiSvgIcon-root": {
                  color: statusColor, // Couleur de l'icône dropdown
                },
              }}
            >
              <MenuItem value="ouvert">Ouvert</MenuItem>
              <MenuItem value="résolu">Résolu</MenuItem>
              <MenuItem value="fermé">Fermé</MenuItem>
            </TextField>
          );
        },
      },
      {
        Header: "Créé par",
        accessor: "identifiant_utilisateur",
      },
    ],
    []
  );

  // Préparation des données pour DataTable
  const data = useMemo(() => (tickets || []), [tickets]);
  console.log("Data pour DataTable :", data); // Log pour débogage

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card
          sx={{
            transition: "all 0.3s ease",
            maxWidth: "800px", // Limiter la largeur de la carte
            width: "100%", // S'assurer qu'elle prend 100% de son conteneur parent
            margin: "0 auto", // Centrer la carte horizontalement
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
            padding: 3,
            borderRadius: 2,
          }}
        >
          {/* Encart pour le titre de la page */}
          <MDBox
            position="relative"
            borderRadius="lg"
            mb={2}
            sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Créer un Ticket
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Signalez un problème ou demandez une nouvelle fonctionnalité
            </Typography>
          </MDBox>

          <Divider sx={{ mb: 3 }} />

          {/* Formulaire de création de ticket */}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Titre */}
              <Grid item xs={12}>
                <TextField
                  label="Titre"
                  name="titre_ticket"
                  value={formData.titre_ticket}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description_ticket"
                  value={formData.description_ticket}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>

              {/* Priorité */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Priorité"
                  name="priorite_ticket"
                  value={formData.priorite_ticket}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                >
                  {priorities.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Catégorie */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Catégorie"
                  name="categorie_ticket"
                  value={formData.categorie_ticket}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Bouton de soumission */}
              <Grid item xs={12}>
                <MDButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<SendIcon />}
                >
                  Soumettre
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>

        {/* Section pour afficher la liste des tickets */}
        <MDBox mt={4}>
          <Card
            sx={{
              transition: "all 0.3s ease",
              maxWidth: "1200px", // Ajuster la largeur selon vos besoins
              width: "100%",
              margin: "0 auto",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
              padding: 3,
              borderRadius: 2,
            }}
          >
            <MDBox
              position="relative"
              borderRadius="lg"
              mb={2}
              sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                Liste des Tickets
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Voir et gérer les tickets existants
              </Typography>
            </MDBox>

            <Divider sx={{ mb: 3 }} />

            {/* DataTable pour afficher les tickets */}
            <DataTable
              table={{ columns, rows: data }}
              canSearch
              entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
              showTotalEntries
              pagination={{ variant: "gradient", color: "info" }}
              isSorted
              noEndBorder
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

CreateTicketPage.propTypes = {
  // Aucun prop requis pour cette page
};

export default CreateTicketPage;
