/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";
import crudsServiceFAPI from "../../../services/cruds-serviceFAPI";

const NewEtab = () => {
  const navigate = useNavigate();
  const [identifiant_etablissement, setIdEtab] = useState({
    text: "",
    error: false,
    textError: "",
  });

  const [denom_etab, setDenomEtab] = useState({
    text: "",
    error: false,
    textError: "",
  });

  const [description, setDescription] = useState("");
  const [descError, setDescError] = useState(false);

  const changeIdEtab = (e) => {
    setIdEtab({ ...identifiant_etablissement, text: e.target.value });
  };

  const changeDenomEtab = (e) => {
    setDenomEtab({ ...denom_etab, text: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (identifiant_etablissement.text.trim().length < 1) {
      setIdEtab({ ...identifiant_etablissement, error: true, textError: "L'identifiant établissement est obligatoire" });
      return;
    }

    if (denom_etab.text.trim().length < 1) {
      setDenomEtab({ ...denom_etab, error: true, textError: "La dénomination de l'établissement est obligatoire" });
      return;
    }

    console.log("verset", e, identifiant_etablissement, denom_etab);

    const etab = {
      identifiant_etablissement: identifiant_etablissement.text,
      denom_etab: denom_etab.text,
    };

    try {
      await crudsServiceFAPI.createEtab(etab);
      navigate("/examples-api/etab-management", {
        state: { value: true, text: "L'établissement a bien été ajouté !" },
      });
    } catch (err) {
      if (err.hasOwnProperty("errors")) {
        setName({ ...identifiant_etablissement, error: true, textError: err.errors[0].detail });
      }
      console.error(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Ajouter un nouvel établissement
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Indiquez les informations pour ajouter le nouvel établissement
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox component="form" method="POST" onSubmit={submitHandler}>
                <MDBox display="flex" flexDirection="column" px={3} my={2}>
                  <MDBox p={1}>
                    <FormField
                      type="text"
                      label="Identifiant établissement"
                      name="identifiant_etablissement"
                      value={identifiant_etablissement.text}
                      onChange={changeIdEtab}
                      error={identifiant_etablissement.error}
                    />{identifiant_etablissement.error && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        {identifiant_etablissement.textError}
                      </MDTypography>
                    )}
                    <FormField
                      type="text"
                      label="Dénomination établissement"
                      name="denom_etab"
                      value={denom_etab.text}
                      onChange={changeDenomEtab}
                      error={denom_etab.error}
                    />
                    {denom_etab.error && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        {denom_etab.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mt={2}>
                    <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Description&nbsp;&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDEditor value={description} onChange={setDescription} />
                    {descError && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        The category description is required
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/examples-api/category-management", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        Back
                      </MDButton>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" type="submit">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewEtab;
