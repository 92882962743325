import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ActiviteInfo from "./components/ActiviteInfo";
import { useLocation } from "react-router-dom"; // Importer useLocation pour récupérer les query params

function ActiviteNouveau() {
  const location = useLocation(); // Utiliser useLocation pour accéder aux query params
  const query = new URLSearchParams(location.search);
  const identifiant_activite = query.get("identifiant_activite"); // Récupérer la dénomination de l'activité
  const parcoursId = query.get("identifiant_parcours"); // Récupérer l'ID du parcours
  console.log("tendance :", parcoursId, identifiant_activite);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Modifier ou créer une activité
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  Remplissez les informations nécessaires pour cette activité.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" form="activite-form">
                  Enregistrer
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ActiviteInfo identifiant_activite={identifiant_activite} parcoursId={parcoursId} /> {/* Passer la dénomination et l'ID du parcours à ActiviteInfo */}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ActiviteNouveau;