import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";  // Ajoutez cette ligne si MDInput est disponible

import { InputLabel, Select, MenuItem, FormControl, Autocomplete } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

// Services
import CrudService from "services/cruds-service";
import crudsServiceFAPI from "services/cruds-serviceFAPI";

const NewUser = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [promotions, setPromotions] = useState([]); // Initialiser avec un tableau vide
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirm: "",
    role: "",
  });

  const [value, setValue] = useState(null); // Initialiser à `null`
  const [promotion, setPromotion] = useState(""); // État pour la promotion sélectionnée

  const [error, setError] = useState({
    name: false,
    email: false,
    password: false,
    confirm: false,
    role: false,
    error: false,
    textError: "",
  });

  // Récupérer les rôles lors du montage du composant
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await CrudService.getRoles();
  //       setRoles(response.data);
  //     } catch (err) {
  //       console.error(err);
  //       return null;
  //     }
  //   })();
  // }, []);

  //  Récupérer les rôles lors du montage du composant
  useEffect(() => {
    
        setRoles("admin");
     
  }, []);

  // Récupérer les promotions lors du montage du composant
  useEffect(() => {
    (async () => {
      try {
        const response = await crudsServiceFAPI.getAllPromotions(); // Appel à l'API pour récupérer les promotions
        setPromotions(response || []); // Stocker les promotions ou un tableau vide
        console.log("PPRI: ", response.data); // Afficher la réponse directement
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);
  
  // Pour vérifier que les promotions sont bien mises à jour, vous pouvez ajouter un autre useEffect
  useEffect(() => {
    console.log("Promotions mises à jour : ", promotions); // Cela s'exécutera chaque fois que `promotions` est mis à jour
  }, [promotions]);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
  
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (user.name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        confirm: false,
        password: false,
        name: true,
        textError: "The name cannot be empty",
      });
      return;
    }
  
    if (user.email.trim().length === 0 || !user.email.trim().match(mailFormat)) {
      setError({
        role: false,
        confirm: false,
        password: false,
        name: false,
        email: true,
        textError: "The email is not valid",
      });
      return;
    }
  
    if (user.password.trim().length < 8) {
      setError({
        name: false,
        email: false,
        role: false,
        confirm: false,
        password: true,
        textError: "The password should have at least 8 characters",
      });
      return;
    }
  
    if (user.confirm.trim() !== user.password.trim()) {
      setError({
        name: false,
        email: false,
        role: false,
        password: false,
        confirm: true,
        textError: "The password and password confirmation do not match",
      });
      return;
    }
  
    if (value === null || value.id === "") {
      setError({
        name: false,
        email: false,
        role: false,
        password: false,
        confirm: false,
        role: true,
        textError: "Role is required",
      });
      return;
    }
  
    if (promotion === "") {
      setError({
        ...error,
        role: true,
        textError: "Promotion is required",
      });
      return;
    }
  
    const newUser = {
      data: {
        type: "users",
        attributes: {
          name: user.name,
          email: user.email,
          password: user.password,
          password_confirmation: user.confirm,
        },
        relationships: {
          roles: {
            data: [
              {
                id: value.id.toString(),
                type: "roles",
              },
            ],
          },
        },
      },
    };
  
    let res = null;
    try {
      // Créer l'utilisateur
     
      console.log("manson : ", res)
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  
    if (res) {
      try {
        // Créer l'élève dans la base via l'API
        const newEleve = {
          identifiant_eleve: res.data.id,  // Utiliser l'identifiant de l'utilisateur créé
          nom_eleve: user.name,
          prenom_eleve: "", // Vous pouvez ajouter une gestion pour prénom si nécessaire
          email: user.email,
          identifiant_promotion: promotion,  // La promotion sélectionnée depuis la liste déroulante
          password: user.password,  // Mot de passe
        };
        console.log("Creating student:", newEleve);
        // Appel à la méthode createEleve du service API pour ajouter l'élève
        await crudsServiceFAPI.createEleve(newEleve);
  
        navigate("/examples-api/user-management", {
          state: { value: true, text: "The user and student were successfully created" },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        return null;
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New User
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the user.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Name"
                        placeholder="Alec"
                        name="name"
                        value={user.name}
                        onChange={changeHandler}
                        error={error.name}
                        inputProps={{
                          autoComplete: "name",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                      {error.name && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Email"
                        placeholder="example@email.com"
                        inputProps={{
                          type: "email",
                          autoComplete: "email",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        name="email"
                        value={user.email}
                        onChange={changeHandler}
                        error={error.email}
                      />
                      {error.email && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={4}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        fullWidth
                        label="Current Password"
                        inputProps={{ type: "password", autoComplete: "" }}
                        name="password"
                        value={user.password}
                        onChange={changeHandler}
                        error={error.password}
                      />
                      {error.password && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        fullWidth
                        label="Confirm Password"
                        inputProps={{ type: "password", autoComplete: "" }}
                        name="confirm"
                        value={user.confirm}
                        onChange={changeHandler}
                        error={error.confirm}
                      />
                      {error.confirm && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column" fullWidth>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      fullWidth
                      marginBottom="1rem"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null} // Utilisez `null` comme valeur par défaut
                        options={roles}
                        getOptionLabel={(option) => (option ? option.attributes.name : "")}
                        value={value ?? null}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value?.id || "")
                        } // Comparer correctement option et valeur
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField {...params} label="Role" InputLabelProps={{ shrink: true }} />
                        )}
                      />
                      {error.role && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          paddingTop="1rem"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>

                    {/* Liste déroulante pour les promotions */}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      fullWidth
                      marginBottom="1rem"
                      marginTop="1rem"
                    >
                      <FormControl fullWidth>
                        <InputLabel id="promotion-select-label">Promotion</InputLabel>
                        <Select
                          labelId="promotion-select-label"
                          id="promotion-select"
                          value={promotion}
                          label="Promotion"
                          onChange={(e) => setPromotion(e.target.value)}
                        >
                          {promotions.map((promo) => (
                            <MenuItem
                              key={promo.identifiant_promotion}
                              value={promo.identifiant_promotion}
                            >
                              {promo.denomination_section} - {promo.section_promotion}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </MDBox>

                  {error.error && (
                    <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/examples-api/user-management", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        Back
                      </MDButton>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" type="submit">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewUser;
