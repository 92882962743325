// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

// Chemin vers votre image
import backgroundImage from "assets/images/bg-reset-cover.jpeg";
import useStore from "services/store"; // Assurez-vous que le chemin est correct

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
  
  
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
      backgroundImage: `url(${backgroundImage})`,  // Utilisation de l'image de fond
      backgroundSize: "cover",  // L'image couvrira toute la zone
      backgroundRepeat: "no-repeat",  // Empêche la répétition de l'image
      backgroundPosition: "center center",  // Centre l'image dans le conteneur
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: white.main,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
