import { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { AuthContext } from "context";
import useStore from "services/store";
import crudsServiceFAPI from "services/cruds-serviceFAPI";

function BasicInfo() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("miaou SIAMES : ", userIdentite, role);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "Non spécifié",
    birthMonth: "Février",
    birthDay: "1",
    birthYear: "2021",
    email: "",
    confirmEmail: "",
    phoneNumber: ""
  });

  useEffect(() => {
    if (userIdentite) {
      setFormData({
        firstName: userIdentite.prenom,
        lastName: userIdentite.nom,
        gender: "Non spécifié",
        birthMonth: "Février",
        birthDay: "1",
        birthYear: "2021",
        email: userIdentite.email,
        confirmEmail: "",
        phoneNumber: ""
      });
    }
  }, [userIdentite]);

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { email, firstName, lastName } = formData; // Données à envoyer
      const userInfo = { nom: lastName, prenom: firstName, email };
      
      const response = await crudsServiceFAPI.updateUserInfos(userUid, userInfo); // Appel du service de mise à jour
      console.log("User info updated:", response);
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const {
    selectedEtab,
    setSelectedEtab,
    selectedSection,
    setSelectedSection,
    selectedPromotion,
    setSelectedPromotion,
    selectedMatiere,
    setSelectedMatiere,
    userId,
    setUserId,
    selectedReferentiel,
    setSelectedReferentiel,
  } = useStore((state) => ({
    selectedEtab: state.selectedEtab,
    setSelectedEtab: state.setSelectedEtab,
    selectedSection: state.selectedSection,
    setSelectedSection: state.setSelectedSection,
    selectedPromotion: state.selectedPromotion,
    setSelectedPromotion: state.setSelectedPromotion,
    selectedMatiere: state.selectedMatiere,
    setSelectedMatiere: state.setSelectedMatiere,
    userId: state.userId,
    setUserId: state.setUserId,
    selectedReferentiel: state.selectedReferentiel,
    setSelectedReferentiel: state.setSelectedReferentiel,
  }));

  console.log("userId : ", userId);

  if (!userIdentite) {
    return <p>Chargement des informations...</p>;
  }

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Rôle : {role} </MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleSubmit} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Prénom"
              placeholder="Jean"
              onChange={(e) => handleChange("firstName", e.target.value)}
              value={formData.firstName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Nom"
              placeholder="Dupont"
              onChange={(e) => handleChange("lastName", e.target.value)}
              value={formData.lastName}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={formData.gender}
                  onChange={(event, newValue) => handleChange("gender", newValue)}
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField {...params} label="Je suis" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      value={formData.birthMonth}
                      onChange={(event, newValue) => handleChange("birthMonth", newValue)}
                      options={selectData.birthDate}
                      renderInput={(params) => (
                        <FormField {...params} label="Date de naissance" InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={formData.birthDay}
                      onChange={(event, newValue) => handleChange("birthDay", newValue)}
                      options={selectData.days}
                      renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      value={formData.birthYear}
                      onChange={(event, newValue) => handleChange("birthYear", newValue)}
                      options={selectData.years}
                      renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              onChange={(e) => handleChange("email", e.target.value)}
              value={formData.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Confirmation email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              onChange={(e) => handleChange("confirmEmail", e.target.value)}
              value={formData.confirmEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <FormField
              label="Numéro de téléphone"
              placeholder="+33 6 43 61 44 91"
              inputProps={{ type: "number" }}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              value={formData.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox ml={2} display="flex" alignItems="center">
              <MDButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{ whiteSpace: "nowrap", ml: 2 }}
              >
                Mettre à jour
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
