import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import { MenuItem, FormControl, Select, Typography, Box, CircularProgress } from "@mui/material";

const ApexChartCrux = () => {
  const { userId, selectedPromotion } = useStore((state) => ({
    userId: state.userId,
    selectedPromotion: state.selectedPromotion,
  }));

  const [selectedOption, setSelectedOption] = useState("competences");
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: { show: true },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.3,
        radius: 10,
        colorScale: {
          ranges: [
            { from: 150, to: 200, name: "Excellence", color: "#00A100" },
            { from: 100, to: 150, name: "Bonne maîtrise", color: "#128FD9" },
            { from: 4, to: 100, name: "Maîtrise partielle", color: "#FFB200" },
            { from: 0, to: 3, name: "Non maîtrisé", color: "#FF0000" },
          ],
        },
      },
    },
    dataLabels: { enabled: true, style: { colors: ["#fff"], fontSize: "12px", fontWeight: "bold" } },
    stroke: { width: 3, colors: ["#ffffff"] },
    // title: { text: "Points " + capitalizeFirstLetter(selectedOption) },
    xaxis: { type: "category", categories: categories },
    yaxis: { title: { text: "Niveaux" }, labels: { style: { fontSize: "12px" } } },
    tooltip: { y: { formatter: (val) => val ? val.toString() : "" } },
  });

  const mapSeries = (response) => {
    if (response.series && Array.isArray(response.series)) {
      const isIndividual = response.series.length > 0 && response.series[0].label;

      const newCategories = isIndividual
        ? response.series.flatMap((item) => item.data.map((point) => point.x)).filter((v, i, a) => a.indexOf(v) === i)
        : response.series.map((item) => item.x);
      
      const newSeries = isIndividual
        ? response.series.map((item) => ({ name: item.label, data: item.data }))
        : [{ name: "Total", data: response.series.map((item) => ({ x: item.x, y: item.y })) }];

      return { newSeries, newCategories };
    } else {
      console.error("Structure de réponse incorrecte");
      return { newSeries: [], newCategories: [] };
    }
  };

  const fetchDataFunctions = {
    competences: userId ? crudsServiceFAPI.getElevePointsCompetences : crudsServiceFAPI.getPromotionPointsCompetencesSum,
    parcours: userId ? crudsServiceFAPI.getElevePointsParcours : crudsServiceFAPI.getPromotionPointsParcours,
    activites: userId ? crudsServiceFAPI.getElevePointsActivites : crudsServiceFAPI.getPromotionPointsActivites,
    blocs: userId ? crudsServiceFAPI.getElevePointsBlocs : crudsServiceFAPI.getPromotionPointsBlocs,
  };

  const fetchPoints = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchDataFunctions[selectedOption](userId || selectedPromotion.identifiant_promotion);
      const { newSeries, newCategories } = mapSeries(response);

      if (!loading) {  // Appliquer uniquement si le chargement est complet
        setSeries(newSeries);
        setCategories(newCategories);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des points :", error);
      setError("Erreur lors de la récupération des points.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((userId || selectedPromotion) && selectedOption) {
      fetchPoints();
    }
  }, [selectedPromotion, selectedOption]);

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      // title: { text: "Points " + capitalizeFirstLetter(selectedOption) },
      xaxis: { ...prevOptions.xaxis, categories: categories },
    }));
  }, [selectedOption, categories]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setError(null);
  };

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Points {capitalizeFirstLetter(selectedOption)}
        </Typography>
        <FormControl variant="outlined" size="small">
          <Select value={selectedOption} onChange={handleOptionChange} displayEmpty>
            <MenuItem value="competences">Compétences</MenuItem>
            <MenuItem value="parcours">Parcours</MenuItem>
            <MenuItem value="activites">Activités</MenuItem>
            <MenuItem value="blocs">Blocs</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="350px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <ReactApexChart options={options} series={series} type="heatmap" height={300} />
      )}
    </div>
  );
};

export default ApexChartCrux;
