import React from 'react';
import MDBox from "components/MDBox"; // Assurez-vous que le chemin d'import est correct pour MDBox

// Composant MultiSegmentProgressBar acceptant les valeurs et l'intitulé en props
const MultiSegmentProgressBar = ({ values, label, label_competence }) => {
  console.log("label : ", label, label_competence)
  // Somme des points obtenus pour ajuster l'échelle
  const totalPoints = values.reduce((acc, value) => acc + value, 0);

  // Couleurs prédéfinies pour les segments
  const colors = ["#FFEB3B", "#FF9800", "#4CAF50", "#2196F3"]; // Rouge, vert clair, vert foncé, bleu

  return (
    <MDBox p={2} borderRadius="lg" boxShadow="md" bgcolor="#ffffff" style={styles.mdBoxContainer}>
      <div style={styles.container}>
        {/* Intitulé à gauche */}
        <span style={styles.label}>{label}</span>

        {/* Barre de progression à droite */}
        <div style={styles.progressBarContainer}>
          {values.map((value, index) => {
            // Calcul de la proportion du segment par rapport au total
            const widthPercentage = (value / totalPoints) * 100;

            return (
              <div
                key={index}
                style={{
                  ...styles.segment,
                  backgroundColor: colors[index % colors.length], // Utilisation des couleurs prédéfinies
                  width: `${widthPercentage}%`, // Calcul basé sur les points obtenus
                }}
              >
                {/* Affichage du texte, ajusté en fonction de la largeur du segment */}
                {widthPercentage >= 10 ? ( // Affiche le texte uniquement si l'espace est suffisant
                  <span style={styles.percentageText}>
                    {`${value} pts`}
                  </span>
                ) : (
                  <span style={styles.percentageTextSmall}>
                    +{value}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Affichage du label_competence sous la barre de progression */}
      <div style={styles.labelCompetence}>
        {label_competence}
      </div>
    </MDBox>
  );
};

// Styles pour la barre de progression, les segments et le label_competence
const styles = {
  mdBoxContainer: {
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    padding: '10px', // Ajustement du padding global
  },
  container: {
    display: 'flex',
    alignItems: 'center', // Centrer verticalement l'intitulé et la barre
    marginBottom: '5px', // Réduction de l'espace entre les barres
  },
  label: {
    marginRight: '10px', // Espace entre l'intitulé et la barre de progression
    fontWeight: 'bold',
    color: '#333',
    fontSize: '16px', // Taille du texte pour le label
  },
  progressBarContainer: {
    display: 'flex',
    width: '100%',
    height: '30px', // Hauteur de la barre de progression
    backgroundColor: '#f0f0f0', // Couleur d'arrière-plan de la barre
    borderRadius: '10px', // Bordure arrondie
    overflow: 'hidden',
    boxShadow: 'inset 0 1px 4px rgba(0, 0, 0, 0.1)', // Ombre interne subtile
  },
  segment: {
    height: '100%', // Chaque segment occupe la hauteur complète de la barre
    display: 'flex',
    justifyContent: 'center', // Centrer horizontalement le texte
    alignItems: 'center', // Centrer verticalement le texte
    color: '#fff', // Couleur du texte (blanc pour contraste avec les couleurs)
    fontWeight: 'bold', // Texte en gras
    fontSize: '14px', // Taille du texte dans la barre
    transition: 'width 0.6s ease', // Animation de la largeur pour une transition plus douce
    position: 'relative', // Position relative pour le texte
  },
  percentageText: {
    position: 'absolute', // Positionnement du texte au centre du segment
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Centre le texte exactement
    pointerEvents: 'none', // Le texte ne doit pas interférer avec les clics
    whiteSpace: 'nowrap', // Empêche le retour à la ligne
    fontSize: '12px', // Taille plus petite pour une meilleure lisibilité
  },
  percentageTextSmall: {
    position: 'absolute', // Positionnement du texte au centre du segment
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Centre le texte exactement
    pointerEvents: 'none', // Le texte ne doit pas interférer avec les clics
    whiteSpace: 'nowrap', // Empêche le retour à la ligne
    fontSize: '10px', // Taille plus petite pour les petits segments
  },
  labelCompetence: {
    textAlign: 'center', // Centrer horizontalement le texte
    fontSize: '13px', // Taille du texte petite
    color: '#888', // Couleur plus douce pour le texte
    marginTop: '5px', // Réduction de l'espace entre la barre et le texte
    fontStyle: 'italic', // Donne un effet plus raffiné
  },
};

export default MultiSegmentProgressBar;