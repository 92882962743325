import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartBarTarget = ({ seriesData }) => {
  const options = {
    chart: {
      height: 50,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    colors: ['#00E396'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      customLegendItems: ['Actual', 'Expected'],
      markers: {
        fillColors: ['#00E396', '#775DD0'],
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={seriesData} type="bar" height={350} />
    </div>
  );
};

export default ApexChartBarTarget;
