import { useState } from "react";
import MDBox from "components/MDBox";
import validationImage from "./validation.jpg"; // Chemin relatif vers validation.jpg

function ValidationImage() {
  const currentImage = validationImage;
  const [imgsViewer, setImgsViewer] = useState(false);

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);

  return (
    <MDBox>
      {currentImage && (
        <>
          

          <MDBox
            component="img"
            src={currentImage}
            alt="Image Validation"
            shadow="lg"
            borderRadius="lg"
            width="50%"
            onClick={openImgsViewer}
          />
        </>
      )}
    </MDBox>
  );
}

export default ValidationImage;
