// ./components/PolarChart/PolarAreaChart.jsx
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import MDTypography from "components/MDTypography";
import { Box, Card } from "@mui/material";

const PolarAreaChart = ({ title, series, options, height = 500 }) => {
  return (
   <>
      <MDTypography variant="h6" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </MDTypography>
      <Box sx={{ width: '100%', paddingBottom: '20px' }}> {/* Ajouter un padding en bas */}
        <ReactApexChart options={options} series={series} type="polarArea" height={height} />
      </Box>
      </>
   
  );
};

export default PolarAreaChart;
