import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CrudServiceFAPI from "services/cruds-serviceFAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "context";
import useStore from "services/store";

function ReferentielManagement() {
  const { userUid, userIdentite, role, ability, isAuthenticated, logout, loading } = useContext(AuthContext);
  const [referentiels, setReferentiels] = useState([]);
  const [selectedReferentiel, setSelectedReferentiel] = useState(null);
  const [selectedBloc, setSelectedBloc] = useState(null);
  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const [notification, setNotification] = useState({ value: false, text: "" });
  const [updated, setUpdated] = useState(false);
  const [blocs, setBlocs] = useState([]);
  const [noBlocs, setNoBlocs] = useState(false);
  const [openCompetenceDialog, setOpenCompetenceDialog] = useState(false);
  const [openBlocDialog, setOpenBlocDialog] = useState(false);
  const [openReferentielDialog, setOpenReferentielDialog] = useState(false);
  const [unites, setUnites] = useState([]); // Added state for units
  const [newReferentiel, setNewReferentiel] = useState({
    identifiant_referentiel: "",
    denomination_referentiel: "",
  });
  const [isEditingReferentiel, setIsEditingReferentiel] = useState(false);
  const [newBloc, setNewBloc] = useState({
    identifiant_bloc: "",
    numero_bloc: "",
    denomination_bloc: "",
    identifiant_referentiel: "",
  });
  const [isEditingBloc, setIsEditingBloc] = useState(false);
  const [newCompetence, setNewCompetence] = useState({
    identifiant_competence: "",
    numero_competence: "",
    denomination_competence: "",
    niveau_competence_visee_referentiel: "",
    identifiant_bloc: "",
    identifiant_unite: "", // Added identifiant_unite to the competence state
  });
  const [isEditingCompetence, setIsEditingCompetence] = useState(false);

  
  const [openUniteDialog, setOpenUniteDialog] = useState(false); // For unit management
  const [isEditingUnite, setIsEditingUnite] = useState(false); // Track if we are editing a unit
  const [newUnite, setNewUnite] = useState({
    identifiant_unite: "",
    denomination_unite: "",
    detail_unite: "",
    identifiant_referentiel: "",
  });

  const selectedEtab = useStore((state) => state.selectedEtab);

  
  // Load referentiels
  useEffect(() => {
    const fetchReferentiels = async () => {
      if(role === "superadministrateur")
      {
      try {
        const response = await CrudServiceFAPI.getAllReferentiels();
        setReferentiels(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des référentiels :", err);
      }}else if (role === "administrateur" && selectedEtab.identifiant_etablissement) {
        try {
          const response = await CrudServiceFAPI.getReferentielsByEtab(selectedEtab.identifiant_etablissement);
          setReferentiels(response);
        } catch (err) {
          console.error("Erreur lors de la récupération des référentiels :", err);
        }
      }else if (role === "gestionnaire" && selectedEtab.identifiant_etablissement) {
        try {
          const response = await CrudServiceFAPI.getReferentielsByProf(userUid,selectedEtab.identifiant_etablissement );
          console.log("response net: ", response )
          setReferentiels(response);
        } catch (err) {
          console.error("Erreur lors de la récupération des référentiels :", err);
        }}
        

    };

    fetchReferentiels();
  }, [updated, selectedEtab]);

  // Gestion des référentiels
  const handleAddReferentielOpen = () => {
    setNewReferentiel({
      identifiant_referentiel: "",
      denomination_referentiel: "",
    });
    setIsEditingReferentiel(false);
    setOpenReferentielDialog(true);
  };

  const handleEditReferentiel = (referentiel) => {
    setNewReferentiel(referentiel);
    setIsEditingReferentiel(true);
    setOpenReferentielDialog(true);
  };

  const handleReferentielDialogClose = () => {
    setOpenReferentielDialog(false);
  };

  const handleReferentielInputChange = (e) => {
    const { name, value } = e.target;
    setNewReferentiel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReferentielSubmit = async () => {
    try {
      if (isEditingReferentiel) {
        await CrudServiceFAPI.updateReferentiel(
          newReferentiel.identifiant_referentiel,
          newReferentiel
        );
      } else {
        await CrudServiceFAPI.createReferentiel(newReferentiel);
      }
      setUpdated((prev) => !prev);
      handleReferentielDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission du référentiel :", err);
    }
  };

  const handleDeleteReferentiel = async (identifiant_referentiel) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce référentiel ?")) {
      try {
        await CrudServiceFAPI.deleteReferentiel(identifiant_referentiel);
        setUpdated((prev) => !prev);
        // Reset selected referentiel if it's the one being deleted
        if (selectedReferentiel && selectedReferentiel.identifiant_referentiel === identifiant_referentiel) {
          setSelectedReferentiel(null);
          setBlocs([]);
          setUnites([]);
        }
      } catch (err) {
        console.error("Erreur lors de la suppression du référentiel :", err);
      }
    }
  };

  // Gestion des unités
  const handleAddUniteOpen = () => {
    setNewUnite({
      denomination_unite: "",
      detail_unite: "",
      identifiant_referentiel: selectedReferentiel.identifiant_referentiel,
    });
    setIsEditingUnite(false);  // Assurez-vous que cette ligne est bien exécutée
    setOpenUniteDialog(true);
  };

  const handleEditUnite = (unite) => {
    setNewUnite(unite);
    setIsEditingUnite(true);
    setOpenUniteDialog(true);
  };

  const handleUniteDialogClose = () => {
    setOpenUniteDialog(false);
  };

  const handleUniteInputChange = (e) => {
    const { name, value } = e.target;
    setNewUnite((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUniteSubmit = async () => {
    try {
      if (isEditingUnite) {
        await CrudServiceFAPI.updateUniteCertificative(
          newUnite.identifiant_unite,
          newUnite
        );
      } else {
        await CrudServiceFAPI.createUniteCertificative(newUnite);
      }
      // Recharger la liste des unités depuis l'API après l'ajout ou la modification
      const updatedUnites = await CrudServiceFAPI.getUnitesByReferentielId(selectedReferentiel.identifiant_referentiel);
      setUnites(updatedUnites);
      setUpdated((prev) => !prev);
      handleUniteDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission de l'unité :", err);
    }
  };
  
  

  const handleDeleteUnite = async (identifiant_unite) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette unité ?")) {
      try {
        await CrudServiceFAPI.deleteUniteCertificative(identifiant_unite);
        setUpdated((prev) => !prev);
      } catch (err) {
        console.error("Erreur lors de la suppression de l'unité :", err);
      }
    }
  };


  // Gestion des blocs
  const handleAddBlocOpen = () => {
    setNewBloc({
      identifiant_bloc: "",
      numero_bloc: "",
      denomination_bloc: "",
      identifiant_referentiel: selectedReferentiel.identifiant_referentiel,
    });
    setIsEditingBloc(false);
    setOpenBlocDialog(true);
  };

  const handleEditBloc = (bloc) => {
    setNewBloc(bloc);
    setIsEditingBloc(true);
    setOpenBlocDialog(true);
  };

  const handleAddBlocClose = () => {
    setOpenBlocDialog(false);
  };

  const handleBlocInputChange = (e) => {
    const { name, value } = e.target;
    setNewBloc((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlocSubmit = async () => {
    try {
      if (isEditingBloc) {
        await CrudServiceFAPI.updateBloc(newBloc.identifiant_bloc, newBloc);
      } else {
        const blocPayload = {
          ...newBloc,
          identifiant_referentiel: selectedReferentiel.identifiant_referentiel,
        };
        await CrudServiceFAPI.createBloc(blocPayload);
      }
      setOpenBlocDialog(false);
      await handleReferentielClick(selectedReferentiel);
    } catch (err) {
      console.error("Erreur lors de la création ou de la mise à jour du bloc :", err);
    }
  };

  const handleDeleteBloc = async (identifiant_bloc) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce bloc ?")) {
      try {
        await CrudServiceFAPI.deleteBloc(identifiant_bloc);
        await handleReferentielClick(selectedReferentiel);
        // Reset selected bloc if it's the one being deleted
        if (selectedBloc && selectedBloc.identifiant_bloc === identifiant_bloc) {
          setSelectedBloc(null);
          setSelectedCompetences([]);
        }
      } catch (err) {
        console.error("Erreur lors de la suppression du bloc :", err);
      }
    }
  };

  // Gestion des compétences
  const handleAddCompetenceOpen = () => {
    if (!selectedReferentiel) {
      alert("Veuillez sélectionner un référentiel d'abord.");
      return;
    }
    setNewCompetence({
      identifiant_competence: "",
      numero_competence: "",
      denomination_competence: "",
      niveau_competence_visee_referentiel: "",
      identifiant_bloc: selectedBloc.identifiant_bloc,
      identifiant_unite: "", // Added identifiant_unite
    });
    setIsEditingCompetence(false);
    setOpenCompetenceDialog(true);
  };

  const handleEditCompetence = (competence) => {
    setNewCompetence(competence);
    setIsEditingCompetence(true);
    setOpenCompetenceDialog(true);
  };

  const handleAddCompetenceClose = () => {
    setOpenCompetenceDialog(false);
  };

  const handleCompetenceInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompetence((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCompetenceSubmit = async () => {
    try {
      if (isEditingCompetence) {
        await CrudServiceFAPI.updateCompetence(
          newCompetence.identifiant_competence,
          {
            ...newCompetence, // Inclut toutes les données de newCompetence
            identifiant_bloc: selectedBloc.identifiant_bloc, // Assure que l'identifiant du bloc est envoyé
          }
        );
      } else {
        const competencePayload = {
          ...newCompetence,
          identifiant_bloc: selectedBloc.identifiant_bloc, // Inclut l'identifiant du bloc pour une nouvelle compétence
        };
        await CrudServiceFAPI.createCompetence(competencePayload);
      }
      setOpenCompetenceDialog(false);
      await handleBlocClick(selectedBloc);
    } catch (err) {
      console.error("Erreur lors de la soumission de la compétence :", err);
    }
  };
  

  const handleDeleteCompetence = async (identifiant_competence) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette compétence ?")) {
      try {
        await CrudServiceFAPI.deleteCompetence(identifiant_competence);
        await handleBlocClick(selectedBloc);
      } catch (err) {
        console.error("Erreur lors de la suppression de la compétence :", err);
      }
    }
  };

  const handleReferentielClick = async (referentiel) => {
    setSelectedReferentiel(referentiel);
    setSelectedBloc(null);
    setSelectedCompetences([]);
    try {
      const blocsResponse = await CrudServiceFAPI.getBlocsByReferentielId(
        referentiel.identifiant_referentiel
      );
      if (blocsResponse.length === 0) {
        setNoBlocs(true);
        setBlocs([]);
      } else {
        setBlocs(blocsResponse);
        setNoBlocs(false);
      }

      // Fetch units associated with the referentiel
      const unitesResponse = await CrudServiceFAPI.getUnitesByReferentielId(
        referentiel.identifiant_referentiel
      );
      setUnites(unitesResponse);
    } catch (err) {
      console.error("Erreur lors de la récupération des blocs ou unités :", err);
      setBlocs([]);
      setUnites([]);
      setNoBlocs(true);
    }
  };


  const handleBlocClick = async (bloc) => {
    setSelectedBloc(bloc);
    try {
      const competences = await CrudServiceFAPI.getCompetencesByBlocId(
        bloc.identifiant_bloc
      );
      setSelectedCompetences(competences);
    } catch (err) {
      console.error("Erreur lors de la récupération des compétences :", err);
      setSelectedCompetences([]);
    }
  };

  // Données pour la table des référentiels
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "identifiant_referentiel", width: "10%" },
      {
        Header: "Dénomination Référentiel",
        accessor: "denomination_referentiel",
        width: "50%",
        Cell: ({ row }) => (
          <MDTypography
            variant="button"
            color="info"
            onClick={() => handleReferentielClick(row.original)}
            style={{ cursor: "pointer" }}
          >
            {row.original.denomination_referentiel}
          </MDTypography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton
              variant="text"
              color="info"
              onClick={() => handleEditReferentiel(row.original)}
            >
              <EditIcon />
            </MDButton>
            <MDButton
              variant="text"
              color="error"
              onClick={() => handleDeleteReferentiel(row.original.identifiant_referentiel)}
            >
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: referentiels,
  };

  // Données pour la table des blocs
  const blocTableData = {
    columns: [
      { Header: "ID Bloc", accessor: "identifiant_bloc", width: "20%" },
      { Header: "Numéro Bloc", accessor: "numero_bloc", width: "20%" },
      {
        Header: "Dénomination Bloc",
        accessor: "denomination_bloc",
        width: "40%",
        Cell: ({ row }) => (
          <MDTypography
            variant="button"
            color="info"
            onClick={() => handleBlocClick(row.original)}
            style={{ cursor: "pointer" }}
          >
            {row.original.denomination_bloc}
          </MDTypography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton
              variant="text"
              color="info"
              onClick={() => handleEditBloc(row.original)}
            >
              <EditIcon />
            </MDButton>
            <MDButton
              variant="text"
              color="error"
              onClick={() => handleDeleteBloc(row.original.identifiant_bloc)}
            >
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: blocs,
  };

  // Données pour la table des compétences
  const competenceTableData = {
    columns: [
      { Header: "ID Compétence", accessor: "identifiant_competence", width: "10%" },
      { Header: "Numéro Compétence", accessor: "numero_competence", width: "10%" },
      { Header: "Dénomination", accessor: "denomination_competence", width: "30%" },
      {
        Header: "Niveau Visé",
        accessor: "niveau_competence_visee_referentiel",
        width: "10%",
      },
      { Header: "ID Unité", accessor: "identifiant_unite", width: "10%" }, // Added column for identifiant_unite
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton
              variant="text"
              color="info"
              onClick={() => handleEditCompetence(row.original)}
            >
              <EditIcon />
            </MDButton>
            <MDButton
              variant="text"
              color="error"
              onClick={() => handleDeleteCompetence(row.original.identifiant_competence)}
            >
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: selectedCompetences,
  };

  useEffect(() => {
    if (selectedReferentiel) {
      const fetchUnites = async () => {
        try {
          const unitesResponse = await CrudServiceFAPI.getUnitesByReferentielId(
            selectedReferentiel.identifiant_referentiel
          );
          setUnites(unitesResponse);
        } catch (err) {
          console.error("Erreur lors de la récupération des unités :", err);
          setUnites([]);
        }
      };
      fetchUnites();
    } else {
      setUnites([]);
    }
  }, [selectedReferentiel]);

   // Données pour la table des unités
   const uniteTableData = {
    columns: [
      { Header: "ID Unité", accessor: "identifiant_unite", width: "20%" },
      { Header: "Dénomination Unité", accessor: "denomination_unite", width: "50%" },
      { Header: "Détails Unité", accessor: "detail_unite", width: "20%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditUnite(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton
              variant="text"
              color="error"
              onClick={() => handleDeleteUnite(row.original.identifiant_unite)}
            >
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: unites,
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                Gestion des référentiels
              </MDTypography>

              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddReferentielOpen}
              >
                + Ajouter un référentiel
              </MDButton>
            </MDBox>
            <DataTable table={dataTableData} />
          </Card>
        </MDBox>

        {/* Affichage des unités du référentiel sélectionné */}
        {selectedReferentiel && (
          <MDBox mb={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDBox mt={4} display="flex" justifyContent="space-between" alignalignItems="center"> <MDTypography variant="h5" fontWeight="medium"> Liste des unités associées au référentiel : {selectedReferentiel.denomination_referentiel} </MDTypography> <MDButton variant="gradient" color="dark" size="small" onClick={handleAddUniteOpen} > + Ajouter une unité </MDButton> </MDBox>
                <MDBox mt={2}>
              {unites.length === 0 ? (
                <MDTypography variant="body2" color="text">
                  Aucune unité disponible pour ce référentiel.
                </MDTypography>
              ) : (
                <DataTable table={uniteTableData} />
              )}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    )}
        {/* Affichage des blocs du référentiel sélectionné */}
        {selectedReferentiel && (
          <>
            <MDBox mb={3}>
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <MDBox
                    mt={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h5" fontWeight="medium">
                      Liste des blocs associés
                    </MDTypography>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={handleAddBlocOpen}
                    >
                      + Ajouter un bloc
                    </MDButton>
                  </MDBox>

                  <MDBox mt={2}>
                    {noBlocs ? (
                      <MDTypography variant="body2" color="text">
                        Aucun bloc disponible pour ce référentiel.
                      </MDTypography>
                    ) : (
                      <DataTable table={blocTableData} />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>

            {/* Affichage des compétences du bloc sélectionné */}
            {selectedBloc && (
              <MDBox mb={3}>
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Détails du bloc : {selectedBloc.denomination_bloc}
                    </MDTypography>
                    <MDBox mt={2}>
                      <MDTypography variant="body1">
                        ID Bloc : {selectedBloc.identifiant_bloc}
                      </MDTypography>
                      <MDTypography variant="body1">
                        Numéro Bloc : {selectedBloc.numero_bloc}
                      </MDTypography>
                      <MDTypography variant="body1">
                        ID Référentiel : {selectedBloc.identifiant_referentiel}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      mt={4}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" fontWeight="medium">
                        Liste des compétences associées
                      </MDTypography>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={handleAddCompetenceOpen}
                      >
                        + Ajouter une compétence
                      </MDButton>
                    </MDBox>
                    <MDBox mt={2}>
                      {selectedCompetences.length === 0 ? (
                        <MDTypography variant="body2" color="text">
                          Aucune compétence disponible pour ce bloc.
                        </MDTypography>
                      ) : (
                        <DataTable table={competenceTableData} />
                      )}
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            )}
          </>
        )}
      </MDBox>

      {/* Popup pour ajouter/modifier un référentiel */}
      <Dialog open={openReferentielDialog} onClose={handleReferentielDialogClose}>
        <DialogTitle>
          {isEditingReferentiel
            ? "Éditer le référentiel"
            : "Ajouter un nouveau référentiel"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Identifiant Référentiel"
            name="identifiant_referentiel"
            fullWidth
            margin="dense"
            value={newReferentiel.identifiant_referentiel}
            onChange={handleReferentielInputChange}
            disabled={isEditingReferentiel}
          />
          <TextField
            label="Dénomination Référentiel"
            name="denomination_referentiel"
            fullWidth
            margin="dense"
            value={newReferentiel.denomination_referentiel}
            onChange={handleReferentielInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReferentielDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleReferentielSubmit} color="primary">
            {isEditingReferentiel ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier un bloc */}
      <Dialog open={openBlocDialog} onClose={handleAddBlocClose}>
        <DialogTitle>{isEditingBloc ? "Éditer le bloc" : "Ajouter un nouveau bloc"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Numéro de bloc"
            name="numero_bloc"
            fullWidth
            margin="dense"
            value={newBloc.numero_bloc}
            onChange={handleBlocInputChange}
          />
          <TextField
            label="Dénomination de bloc"
            name="denomination_bloc"
            fullWidth
            margin="dense"
            value={newBloc.denomination_bloc}
            onChange={handleBlocInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddBlocClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleBlocSubmit} color="primary">
            {isEditingBloc ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier une compétence */}
      <Dialog open={openCompetenceDialog} onClose={handleAddCompetenceClose}>
        <DialogTitle>
          {isEditingCompetence ? "Éditer la compétence" : "Ajouter une compétence"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Numéro de compétence"
            name="numero_competence"
            fullWidth
            margin="dense"
            value={newCompetence.numero_competence}
            onChange={handleCompetenceInputChange}
          />
          <TextField
            label="Dénomination de compétence"
            name="denomination_competence"
            fullWidth
            margin="dense"
            value={newCompetence.denomination_competence}
            onChange={handleCompetenceInputChange}
          />
          <TextField
            label="Niveau visé"
            name="niveau_competence_visee_referentiel"
            fullWidth
            margin="dense"
            value={newCompetence.niveau_competence_visee_referentiel}
            onChange={handleCompetenceInputChange}
          />
          {/* New field for identifiant_unite */}
          <TextField
            select
            label="Unité Certificative"
            name="identifiant_unite"
            fullWidth
            margin="dense"
            value={newCompetence.identifiant_unite}
            onChange={handleCompetenceInputChange}
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Sélectionnez une unité</option>
            {unites && unites.map((unite) => (
  <option key={unite.identifiant_unite} value={unite.identifiant_unite}>
    {unite.denomination_unite}
  </option>
))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddCompetenceClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleCompetenceSubmit} color="primary">
            {isEditingCompetence ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

{/* Popup pour ajouter/modifier une unité */}
<Dialog open={openUniteDialog} onClose={handleUniteDialogClose}>
    <DialogTitle>{isEditingUnite ? "Éditer l'unité" : "Ajouter une unité"}</DialogTitle>
    <DialogContent>
      {/* <TextField
        label="Identifiant Unité"
        name="identifiant_unite"
        fullWidth
        margin="dense"
        value={newUnite.identifiant_unite}
        onChange={handleUniteInputChange}
        disabled={isEditingUnite}
      /> */}
      <TextField
        label="Dénomination Unité"
        name="denomination_unite"
        fullWidth
        margin="dense"
        value={newUnite.denomination_unite}
        onChange={handleUniteInputChange}
      />
      <TextField
        label="Détail Unité"
        name="detail_unite"
        fullWidth
        margin="dense"
        value={newUnite.detail_unite}
        onChange={handleUniteInputChange}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleUniteDialogClose} color="secondary">
        Annuler
      </Button>
      <Button onClick={handleUniteSubmit} color="primary">
        {isEditingUnite ? "Mettre à jour" : "Ajouter"}
      </Button>
    </DialogActions>
  </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ReferentielManagement;
