import { useContext, useEffect } from "react";
import { AuthContext } from "context";
import AuthService from "services/auth-service";

const Logout = ({ children }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const handleLogout = async () => {
      // Appel au service de déconnexion
      await AuthService.logout();

      // Suppression du rôle de localStorage
      
      // Appel à la méthode de logout dans le contexte d'authentification
      authContext.logout();
    };

    handleLogout();
  }, [authContext]); // Ajoutez authContext comme dépendance pour éviter les erreurs de hook

  return <>{children}</>;
};

export default Logout;