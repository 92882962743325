import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "context";
import { AbilityContext } from "Can";

const ProtectedRoute = ({ protectedRoute, redirectPath = "/login", children }) => {
  const { isAuthenticated, userRights, loading } = useContext(AuthContext);
  const ability = useContext(AbilityContext);

  // Charger uniquement si userRights est disponible
  //const hasPermission = userRights?.[0]?.gestion;
  const hasPermission = ability.can("view", "gestion");
  //console.log("pipu : ", res)
  // Attendre que les droits soient chargés
  if (loading) {
    return null; // Peut être remplacé par un composant de chargement si nécessaire
  }

  if (protectedRoute) {
    // Vérifier si l'utilisateur est authentifié
    if (!isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }

    // Vérifier si l'utilisateur a la permission requise
    if (!hasPermission) {
      return <Navigate to="/pages/profile/profile-overview" replace />;
    }
  }

  return children; // Rendre les enfants si toutes les conditions sont remplies
};

export default ProtectedRoute;
