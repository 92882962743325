import Axios from "axios";

const API_URL = process.env.REACT_APP_FAPI_URL;
console.log("esclaveFAPI : ", API_URL);
Axios.defaults.baseURL = API_URL;

export class HttpServiceFAPI {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  post = async (url, data) => await this.request(this.getOptionsConfig("post", url, data));

  put = async (url, data) => await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) => await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url) => await this.request(this.getOptionsConfig("delete", url));

  getOptionsConfig = (method, url, data) => {
    return { method, url, data, headers: { "Content-Type": "application/vnd.api+json" } };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => {
          if (ex.response && ex.response.data) {
            reject(ex.response.data);
          } else {
            reject({ message: "An error occurred" });
          }
        });
    });
}
}

export default new HttpServiceFAPI();
