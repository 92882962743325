import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ParcoursInfo from "layouts/parcours/parcours-gestion/parcours-nouveau/components/ParcoursInfo";
import crudsServiceFAPI from "services/cruds-serviceFAPI"; // Importer le service
import useStore from "services/store"; // Importer le Zustand store

function ParcoursNouveau() {
  const queryParams = new URLSearchParams(location.search); // Initialiser queryParams
  const identifiant_parcours = queryParams.get('identifiant_parcours') || false; // Récupère le paramètre depuis la chaîne de requête
  
  const navigate = useNavigate();

  const [matiereid, setMatiereId] = useState("");

  // Use Zustand store to retrieve the selected subject (matière)
  const selectedMatiere = useStore((state) => state.selectedMatiere);

  // Update matiere state when selectedMatiere changes
  useEffect(() => {
    if (selectedMatiere) {

      setMatiereId(selectedMatiere.identifiant_matiere);
    }
  }, [selectedMatiere]);

  console.log("milu : ", matiereid)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                {identifiant_parcours ? "Modifier le parcours" : "Créer un nouveau parcours"}
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  {identifiant_parcours ? "Modifiez les informations du parcours ci-dessous." : "Créez un parcours en remplissant les informations ci-dessous."}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" form="parcours-form">
                  {identifiant_parcours ? "Enregistrer les modifications" : "Créer"}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ParcoursInfo identifiant_parcours={identifiant_parcours} matiere={matiereid} navigate={navigate} /> {/* Passer l'ID du parcours correctement */}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ParcoursNouveau;