// @mui material components
import { useState, useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Import du service
import crudsServiceFAPI from "services/cruds-serviceFAPI";

import { AuthContext } from "context";

function ChangePassword() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("password : ", userUid, role);
  // États pour les champs de mot de passe
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const passwordRequirements = [
    "One special character",
    "Min 6 characters",
    "One number (2 are recommended)",
  ];

  const validatePassword = (password) => {
    if (password.length < 6) {
      return "Le mot de passe doit contenir au moins 6 caractères";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return "Le mot de passe doit contenir au moins un caractère spécial";
    }
    if (!/\d/.test(password)) {
      return "Le mot de passe doit contenir au moins un chiffre";
    }
    return "";
  };

  const handleNewPasswordChange = (e) => {
    const newPwd = e.target.value;
    setNewPassword(newPwd);
    
    // Validation en temps réel
    const error = validatePassword(newPwd);
    setErrorMessage(error);
  };

  const handleConfirmNewPasswordChange = (e) => {
    const confirmPwd = e.target.value;
    setConfirmNewPassword(confirmPwd);

    // Vérifie que les deux mots de passe correspondent
    if (newPassword && confirmPwd !== newPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas");
    } else {
      setErrorMessage(""); // Efface le message si les mots de passe correspondent
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (errorMessage) return;
  
    try {
      await crudsServiceFAPI.updateUserPassword(userUid, {
        old_password: currentPassword,
        new_password: newPassword,
      });
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setErrorMessage("Mot de passe mis à jour avec succès !");
    } catch (error) {
      setErrorMessage("Erreur lors de la mise à jour du mot de passe");
    }
  };
  

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleUpdatePassword} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Current Password"
              inputProps={{ type: "password", autoComplete: "" }}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Confirm New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
            />
          </Grid>
        </Grid>
        {errorMessage && (
          <MDBox mt={2}>
            <MDTypography variant="caption" color="error">
              {errorMessage}
            </MDTypography>
          </MDBox>
        )}
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {passwordRequirements.map((req, idx) => (
              <MDBox key={`req-${idx}`} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
                  {req}
                </MDTypography>
              </MDBox>
            ))}
          </MDBox>
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" type="submit">
              Update Password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
