// App.js
import { useState, useEffect, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { setupAxiosInterceptors } from "services/interceptor";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import crudRoutes from "crud.routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, AuthContext } from "context";
import { getPermissions } from "config/Permissions";
import brandWhite from "assets/images/learnia_logo_transparent.png";
import brandDark from "assets/images/logo-ct-dark.png";
import ProtectedRoute from "examples/ProtectedRoute";
import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import AuthService from "services/auth-service"; 
import { Helmet } from "react-helmet";

export default function App({ ability }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const authContext = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState({ name: "", image: "" });

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "false");
  }, []);

  // Gestion des interceptions Axios
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  // Cache pour RTL
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Ouvrir le sidenav au survol si mini
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Fermer le sidenav au retrait du survol
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Ouvrir/fermer le configurateur
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Définir l'attribut direction pour le body
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Scroll en haut lors du changement de route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Mise à jour des permissions si nécessaire
  useEffect(() => {
    if (!authContext.isAuthenticated) return;

    // Exemple de mise à jour des permissions avec CASL si nécessaire
    // const staticRules = getPermissions();
    // ability.update(staticRules);
  }, [authContext.isAuthenticated]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.key === "user-name") {
        route.name = userDetails.name;
        route.icon = <MDAvatar src={userDetails.image} alt="Utilisateur" size="sm" />;
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? (
                <ProtectedRoute protectedRoute={route.protected}>
                  {route.component}
                </ProtectedRoute>
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const getCrudRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getCrudRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? (
                <ProtectedRoute protectedRoute={route.protected}>
                  {route.component}
                </ProtectedRoute>
              ) : (
                route.component
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      {isDemo && (
        <Helmet>
        </Helmet>
      )}
      {direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
          <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Learnia PRO"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                  sx={{
                    "& .MuiDrawer-paper .MuiBox-root img": {
                      width: "200px",  // Ajustez la largeur ici
                      height: "auto",  // Conservez le ratio d'aspect
                    },
                  }}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/login" element={<Login />} /> {/* Ajoutez cette ligne */}
              {getRoutes(routes)}
              {getCrudRoutes(crudRoutes)}
              <Route path="*" element={<Navigate to="/pages/profile/profile-overview" />} />
            </Routes>
          </ThemeProvider>
        </CacheProvider>
      ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="&nbsp; &nbsp;&nbsp;Learnia PRO"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{
                  "& .MuiDrawer-paper .MuiBox-root img": {
                    width: "80px",  // Ajustez la largeur ici
                    height: "auto",  // Conservez le ratio d'aspect
                  },
                }}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/login" element={<Login />} /> {/* Ajoutez cette ligne */}
            {getRoutes(routes)}
            {getCrudRoutes(crudRoutes)}
            <Route path="*" element={<Navigate to="/pages/profile/profile-overview" />} />
          </Routes>
        </ThemeProvider>
      )}
    </>
  );
}
