// Overview.jsx
// @mui material components
import Grid from "@mui/material/Grid";
import React, { useEffect, useState, useRef } from "react";
import BookingCard from "examples/Cards/ActiviteCard";
import { Box } from "@mui/material";
import MultiSegmentProgressBar from "./MultiSegmentProgressBar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data and services
import crudsServiceFAPI from "services/cruds-serviceFAPI"; // Importer le service
import useStore from 'services/store'; // Import du store

// Additional imports
import Card from "@mui/material/Card";
import ReactApexChart from "react-apexcharts";
import ApexChartCrux from "layouts/parcours/parcours-espace/parcours-grille/components/ApexChartCrux";
import Header from "layouts/pages/profile/components/Header";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import unite from "assets/images/unites/unite.jpg";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Import du nouveau composant PolarAreaChart
import PolarAreaChart from "./components/PolarChart/PolarAreaChart";

function Overview() {
  const {
    selectedEtab,
    setSelectedEtab,
    selectedSection,
    setSelectedSection,
    selectedPromotion,
    setSelectedPromotion,
    selectedMatiere,
    setSelectedMatiere,
    userId,
    setUserId,
    userIdentite,
    setUserIdentite,
    selectedReferentiel,
    setSelectedReferentiel,
  } = useStore((state) => ({
    selectedEtab: state.selectedEtab,
    setSelectedEtab: state.setSelectedEtab,
    selectedSection: state.selectedSection,
    setSelectedSection: state.setSelectedSection,
    selectedPromotion: state.selectedPromotion,
    setSelectedPromotion: state.setSelectedPromotion,
    selectedMatiere: state.selectedMatiere,
    setSelectedMatiere: state.setSelectedMatiere,
    userId: state.userId,
    setUserId: state.setUserId,
    userIdentite: state.userIdentite,
    setUserIdentite: state.setUserIdentite,
    selectedReferentiel: state.selectedReferentiel,
    setSelectedReferentiel: state.setSelectedReferentiel,
  }));

  const userUid = userId;
  
  const [blocs, setBlocs] = useState([]);
  const [referentielId, setReferentielId] = useState(null);
  const [parcoursList, setParcoursList] = useState([]); // Liste des parcours
  const [activitesList, setActivitesList] = useState([]); // Liste des activités
  const [selectedParcours, setSelectedParcours] = useState(""); // Parcours sélectionné
  const [selectedActivite, setSelectedActivite] = useState(""); // Activité sélectionnée
  const [unites, setUnites] = useState([]); // État pour les unités

  const [pointsData, setPointsData] = useState({ students: [], parcours: [], total_points: 0 });

  const [loadingPoints, setLoadingPoints] = useState(false); // État de chargement des points

  const [pointsBloc, setPointsBloc] = useState([]);
  const [pointsActivites, setPointsActivites] = useState([]);
  const [error, setError] = useState(null);

  const generateColors = (numColors) => {
    const palette = [
      '#FF4560',
      '#00E396',
      '#775DD0',
      '#FEB019',
      '#008FFB',
      '#00E396',
      '#775DD0',
      '#FEB019',
      '#008FFB',
      '#FF4560',
      // Ajoutez plus de couleurs si nécessaire
    ];
    if (numColors <= palette.length) {
      return palette.slice(0, numColors);
    } else {
      // Répéter la palette si le nombre de couleurs requis dépasse la palette
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        colors.push(palette[i % palette.length]);
      }
      return colors;
    }
  };
  
  // Fonction pour créer la configuration des graphiques Polar Area
  const createPolarAreaChartsConfig = (data) => {
    return data.map((item, index) => ({
      id: index + 1, // ID unique basé sur l'index (1, 2, 3, ...)
      title: item.title,
      series: item.series,
      options: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff'],
        },
        fill: {
          opacity: 0.8,
        },
        labels: item.labels,
        colors: generateColors(item.series.length),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        },
      },
    }));
  };
  

  // const chartData = [
  //   {
  //     title: 'U1',
  //     labels: ['C1', 'C2', 'C8', 'C10', 'C11', 'C23'],
  //     series: [14, 23, 21, 17, 15, 10],
  //   },
  //   {
  //     title: 'U2',
  //     labels: ['C4', 'C6', 'C7', 'C9', 'C19', 'C20', 'C21'],
  //     series: [10, 15, 20, 25, 30, 35, 40],
  //   },
  //   {
  //     title: 'U3',
  //     labels: ['C3', 'C7', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18'],
  //     series: [10, 15, 20, 25, 30, 35, 40, 45, 50],
  //   },
  //   // Ajoutez autant d'objets que nécessaire
  // ];


  // Configuration des séries pour le graphique "Évolution des points acquis"
  
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      if (userId && referentielId) {
        try {
          const response = await crudsServiceFAPI.getCompetencesUnitesByEleveId(userId, referentielId);
          // Assurez-vous que les données sont dans response.data
          console.log("jouer : ", response)
          setChartData(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des données de chartData :", error);
        }
      }else if (referentielId && !userId){
        console.log("pain : ", selectedPromotion)
        try {
          const response = await crudsServiceFAPI.getCompetencesUnitesByPromotionId(selectedPromotion.identifiant_promotion, referentielId);
          console.log("jouer promotion : ", response)
          setChartData(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des données de chartData :", error);
        }

      }
    };
  
    fetchChartData();
  }, [userId, referentielId]);

  
  
  const [series, setSeries] = useState([]);

  // Options pour le graphique "Évolution des points acquis"
  const [options, setOptions] = useState({
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: { enabled: false },
    markers: { size: 0 },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) { return (val / 1).toFixed(0); }
      },
      title: { text: 'Points' }
    },
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: false,
      y: { formatter: function (val) { return val.toFixed(0); } }
    }
  });

  // Configuration dynamique des Polar Area Charts
  const [polarAreaChartsConfig, setPolarAreaChartsConfig] = useState([]);

  useEffect(() => {
    // Créer la configuration des graphiques à partir des données
    const config = createPolarAreaChartsConfig(chartData);
    setPolarAreaChartsConfig(config);
  }, [chartData]);

  useEffect(() => {
    // Fonction pour récupérer les référentiels
    const fetchReferentielData = async () => {
      try {
        console.log("communauté : ", selectedPromotion);
        
        const response = await crudsServiceFAPI.getReferentielsParPromotion(selectedPromotion.identifiant_promotion);
        
        console.log("vivre : ", response);
        
        console.log("Résultat de la promesse:", response[0]?.identifiant_referentiel);
        setReferentielId(response[0]?.identifiant_referentiel);
      } catch (error) {
        console.error("Erreur lors de l'exécution de la promesse:", error);
      }
    };

    if (selectedPromotion) { // Vérifier que selectedPromotion est défini
      fetchReferentielData();
    }
  }, [selectedPromotion]);

  // Initialiser pdfRefs comme un tableau
  const pdfRefs = useRef([]);

  // Fonction pour ajouter une référence au tableau
  const addToRefs = (el) => {
    if (el && !pdfRefs.current.includes(el)) {
      pdfRefs.current.push(el);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("mort", userId)
        if(userId === null){
          // Appel pour les points d'un bloc spécifique
          const data = await crudsServiceFAPI.getPointsParDateBlocsEtTotaux(selectedPromotion.identifiant_promotion);
          console.log("mort null", data)
          setSeries(data)
        } else{
          console.log("mort pas nul ", userId)
          const data = await crudsServiceFAPI.getPointsParDateBlocsEtTotauxEleve(userId);
          console.log("mort pas nul ", data)
          setSeries(data)
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [userId, selectedPromotion]); // Ajout de selectedPromotion comme dépendance si nécessaire

  useEffect(() => {
    const fetchUnites = async () => {
      try {
        // Si referentielId et userId sont définis, récupérer les unités pour l'élève
        if (referentielId && userId) {
          console.log("Fetching unites for eleve:", userId, "referentielId:", referentielId);
          const result = await crudsServiceFAPI.getUnitesByReferentielAndEleve(referentielId, userId );
          console.log("unites récupérés pour l'élève :", result);
          setUnites(result || []);

        // Si userId est manquant mais selectedPromotion est défini, récupérer les unités pour la promotion
        } else if (selectedPromotion && selectedPromotion.identifiant_promotion) {
          console.log("Fetching blocs for promotion:", selectedPromotion.identifiant_promotion);
          const result = await crudsServiceFAPI.getUnitesByReferentielAndPromotion(referentielId,selectedPromotion.identifiant_promotion);
          console.log("Unités récupérés pour la promotion :", result);
          setUnites(result || []);

        } else {
          console.error("Impossible de récupérer les unites : paramètres manquants (referentielId ou selectedPromotion)");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des unites et compétences:", error);
      }
    };
  
    // Déclencher fetch uniquement si referentielId ou selectedPromotion est disponible
    if ((userId && referentielId) || (selectedPromotion && selectedPromotion.identifiant_promotion)) {
      fetchUnites();
    }
  }, [userId, referentielId, selectedPromotion]);

  // Récupérer les blocs et compétences pour l'élève
  useEffect(() => {
    const fetchBlocs = async () => {
      console.log("selectedReaferentiel : ", referentielId)
      try {
        // Si referentielId et userId sont définis, récupérer les blocs pour l'élève
        if (referentielId && userId) {
          console.log("Fetching blocs for eleve:", userId, "referentielId:", referentielId);
          const result = await crudsServiceFAPI.getBlocsAndCompetencesForEleve(userId, referentielId);
          console.log("Blocs et compétences récupérés pour l'élève :", result);
          setBlocs(result || []);

        // Si userId est manquant mais selectedPromotion est défini, récupérer les blocs pour la promotion
        } else if (selectedPromotion && selectedPromotion.identifiant_promotion) {
          console.log("Fetching blocs for promotion:", selectedPromotion.identifiant_promotion);
          const result = await crudsServiceFAPI.getBlocsAndCompetencesForPromotion(selectedPromotion.identifiant_promotion, referentielId);
          console.log("Blocs et compétences récupérés pour la promotion :", result);
          setBlocs(result || []);

        } else {
          console.error("Impossible de récupérer les blocs : paramètres manquants (referentielId ou selectedPromotion)");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des blocs et compétences:", error);
      }
    };
  
    // Déclencher fetch uniquement si referentielId ou selectedPromotion est disponible
    if ((userId && referentielId) || (selectedPromotion && selectedPromotion.identifiant_promotion)) {
      fetchBlocs();
    }
  }, [userId, referentielId, selectedPromotion]);

  // Récupérer la liste des parcours lorsque selectedMatiere est disponible
  useEffect(() => {
    const fetchParcours = async () => {
      if (selectedMatiere && selectedMatiere.identifiant_matiere) {
        try {
          const parcours = await crudsServiceFAPI.getParcoursByMatiereId(selectedMatiere.identifiant_matiere);
          setParcoursList(parcours);
        } catch (error) {
          console.error("Erreur lors de la récupération des parcours :", error);
        }
      }
    };
    fetchParcours();
  }, [selectedMatiere]);

  // Récupérer la liste des activités lorsque selectedParcours change
  useEffect(() => {
    const fetchActivites = async () => {
      if (selectedParcours) {
        try {
          const activites = await crudsServiceFAPI.getActivitesByParcoursId(selectedParcours);
          setActivitesList(activites);
        } catch (error) {
          console.error("Erreur lors de la récupération des activités :", error);
        }
      } else {
        setActivitesList([]); // Réinitialiser les activités si aucun parcours n'est sélectionné
      }
    };
    fetchActivites();
  }, [selectedParcours]);

  // Récupérer les données de points en fonction des sélections
  useEffect(() => {
    const fetchPoints = async () => {
      if ( !selectedParcours && !selectedActivite && selectedEtab && selectedSection && selectedPromotion) {
        // Aucune sélection, récupérer les données générales
        try {
          setLoadingPoints(true);
          const data = await crudsServiceFAPI.getPromotionPoints(selectedPromotion.identifiant_promotion);
          console.log("Données générales de points :", data);
          setPointsData({
            students: data.students || [],
            parcours: data.parcours || [],
            total_points: data.total_points || 0
          });
        } catch (error) {
          console.error("Erreur lors de la récupération des points généraux :", error);
          setPointsData({ students: [], parcours: [], total_points: 0 });
        } finally {
          setLoadingPoints(false);
        }
      } else if (selectedParcours && !selectedActivite) {
        // Parcours sélectionné, récupérer les points par parcours pour chaque élève
        try {
          setLoadingPoints(true);
          const data = await crudsServiceFAPI.getPromotionStudentsPoints(selectedPromotion.identifiant_promotion);
          console.log("Données de points par parcours :", data);
          // Filtrer les points par parcours sélectionné
          const filteredData = {
            students: data.students?.map((student) => ({
              ...student,
              parcours: student.parcours?.filter((parcours) => parcours.parcours_id === selectedParcours) || []
            })) || [],
            parcours: data.parcours || [],
            total_points: data.total_points || 0
          };
          setPointsData(filteredData);
        } catch (error) {
          console.error("Erreur lors de la récupération des points par parcours :", error);
          setPointsData({ students: [], parcours: [], total_points: 0 });
        } finally {
          setLoadingPoints(false);
        }
      } else if (selectedActivite) {
        // Activité sélectionnée, récupérer les points par activité pour chaque élève
        try {
          setLoadingPoints(true);
          const data = await crudsServiceFAPI.getPromotionStudentsPoints(selectedPromotion.identifiant_promotion);
          console.log("Données de points par activité :", data);
          // Filtrer les points par activité sélectionnée
          const filteredData = {
            students: data.students?.map((student) => ({
              ...student,
              parcours: student.parcours?.map((parcours) => ({
                ...parcours,
                activities: parcours.activities?.filter((activity) => activity.activity_id === selectedActivite) || []
              })) || []
            })) || [],
            parcours: data.parcours || [],
            total_points: data.total_points || 0
          };
          setPointsData(filteredData);
        } catch (error) {
          console.error("Erreur lors de la récupération des points par activité :", error);
          setPointsData({ students: [], parcours: [], total_points: 0 });
        } finally {
          setLoadingPoints(false);
        }
      }
    };
    fetchPoints();
  }, [selectedParcours, selectedActivite, selectedPromotion]);

  // Préparer les données des BookingCards
  console.log("plein : ", unites)
  const bookingCardsData = unites.map((uniteItem) => ({
    image: unite,
    title: `Unité ${uniteItem.denomination_unite}`,
    description: uniteItem.detail_unite,
    price: uniteItem.points_remportes,
    location: `Référentiel: ${uniteItem.identifiant_referentiel}`,
  }));

  // Fonction pour générer le PDF
  const generatePDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    let currentHeight = 10; // Position initiale Y

    for (let i = 0; i < pdfRefs.current.length; i++) {
      const element = pdfRefs.current[i];
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pdfWidth - 20; // Marges de 10mm de chaque côté
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Si l'image dépasse la page actuelle, ajouter une nouvelle page
      if (currentHeight + imgHeight > pdf.internal.pageSize.getHeight() - 10) {
        pdf.addPage();
        currentHeight = 10; // Réinitialiser la position Y pour la nouvelle page
      }

      pdf.addImage(imgData, 'PNG', 10, currentHeight, imgWidth, imgHeight);
      currentHeight += imgHeight + 10; // Ajouter une marge de 10mm entre les sections
    }

    pdf.save(`Tableau_de_bord_${new Date().toISOString().split('T')[0]}.pdf`);
  };

  // Fonction pour ajouter un nouveau Polar Area Chart
  const addPolarAreaChart = () => {
    const newId = polarAreaChartsConfig.length + 1;
    const newChart = {
      id: newId,
      title: `Polar Area Chart - Dataset ${newId}`,
      series: [
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
        Math.floor(Math.random() * 100),
      ],
      options: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        labels: ['New Label 1', 'New Label 2', 'New Label 3', 'New Label 4', 'New Label 5', 'New Label 6', 'New Label 7', 'New Label 8', 'New Label 9'],
        colors: ['#FF4560', '#00E396', '#775DD0', '#FEB019', '#008FFB', '#00E396', '#775DD0', '#FEB019', '#008FFB'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        legend: {
          position: 'bottom',          // Positionner la légende en bas
          horizontalAlign: 'center',   // Aligner horizontalement la légende au centre
          offsetY: 0,                  // Ajuster l'offset vertical si nécessaire
          fontSize: '12px',            // Réduire la taille de la police pour éviter le chevauchement
          markers: {
            width: 10,
            height: 10,
            radius: 2
          },
          itemMargin: {
            horizontal: 5,
            vertical: 5
          }
        }
      }
    };
    setPolarAreaChartsConfig([...polarAreaChartsConfig, newChart]);
  };

  // Fonction pour supprimer un Polar Area Chart
  const removePolarAreaChart = (id) => {
    const updatedCharts = polarAreaChartsConfig.filter(chart => chart.id !== id);
    setPolarAreaChartsConfig(updatedCharts);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
        {/* Bouton de Génération de PDF */}
        <MDBox display="flex" justifyContent="flex-end" mb={2}>
          <MDButton variant="contained" color="secondary" onClick={generatePDF}>
            Générer PDF
          </MDButton>
        </MDBox>

        {/* Section pour le tableau de bord des compétences */}
        <MDBox mt={2} mb={5} ref={addToRefs}>
          <Grid container spacing={1} justifyContent="center">
            <MDBox textAlign="center" mb={1}>
              <MDTypography variant="h3" fontWeight="bold" color="primary" gutterBottom>
                Tableau de bord
              </MDTypography>
            </MDBox>
            <MDBox p={3} sx={{ backgroundColor: "#f0f0f0", borderRadius: "8px", width: "100%" }}>
              <MDTypography variant="h6" fontWeight="medium" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", marginRight: "10px" }}>🔰</span>
                Niveau 1 : J'en ai entendu parler, j'ai des connaissances.
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", marginRight: "10px" }}>🔧</span>
                Niveau 2 : Avec mes connaissances, je peux reproduire une situation similaire.
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", marginRight: "10px" }}>📈</span>
                Niveau 3 : Avec mes connaissances et mon expérience, je peux répondre à un besoin similaire.
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", marginRight: "10px" }}>🌟</span>
                Niveau 4 : Avec mes connaissances et mes expériences, je peux répondre à un nouveau besoin.
              </MDTypography>
            </MDBox>
          </Grid>
        </MDBox>

        

        {/* Section pour afficher les BookingCards */}
        <MDBox mt={1} ref={addToRefs}>
          <Grid container spacing={4}>
            {bookingCardsData.map((card, index) => (
              <Grid item xs={12} md={4} key={index}>
                <BookingCard
                  image={card.image}
                  title={card.title}
                  description={card.description}
                  price={card.price}
                  location={card.location}
                  sx={{
                    height: 220,  // Réduire la hauteur de la carte
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Ajustement de l'image */}
                  <Box
                    component="img"
                    src={card.image}
                    alt={card.title}
                    sx={{
                      height: "120px",  // Taille de l'image réduite
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                      marginBottom: 2,
                    }}
                  />
                </BookingCard>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Header>

      {/* Graphique ApexChartCrux */}
      <Grid mt={1} item xs={12} ref={addToRefs}>
        <Card
          sx={{ width: "100%", margin: "auto", boxShadow: 3, borderRadius: 2, mb: 4, p: 3 }}
        >
          {/* Section pour Ajouter des Polar Area Charts Dynamiques */}
          <MDTypography variant="h4" fontWeight="bold" color="primary" sx={{ mb: 1 }}>
            Compétences par unités
          </MDTypography>

          {/* Section pour les Polar Area Charts Dynamiques */}
          <MDBox mt={2} mb={5}>
            <Grid container spacing={0}> {/* Réduire l'espacement ici */}
              {polarAreaChartsConfig.map((chartConfig) => {
                // Définir les options mises à jour pour chaque graphique
                const updatedOptions = {
                  ...chartConfig.options,
                  legend: {
                    position: 'bottom',          // Positionner la légende en bas
                    horizontalAlign: 'center',   // Aligner horizontalement la légende au centre
                    offsetY: 50,                  // Ajuster l'offset vertical si nécessaire
                    fontSize: '12px',            // Réduire la taille de la police pour éviter le chevauchement
                    markers: {
                      width: 10,
                      height: 10,
                      radius: 2
                    },
                    itemMargin: {
                      horizontal: 5,
                      vertical: -1
                    }
                  },
                  chart: {
                    ...chartConfig.options.chart,
                    height: 350,                  // Ajuster la hauteur du graphique si nécessaire
                  },
                  responsive: [{
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      },
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }]
                };

                return (
                  <Grid item xs={12} sm={6} md={4} key={chartConfig.id}>
                    <PolarAreaChart
                      title={chartConfig.title}
                      series={chartConfig.series}
                      options={updatedOptions}
                      height={400} // Ajuster la hauteur si nécessaire
                    />
                   
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
        </Card>
      </Grid>

      {/* Affichage des blocs récupérés */}
      <MDBox mt={6} ref={addToRefs}>
        <Grid container spacing={2}>
          {blocs && Array.isArray(blocs) && blocs.length > 0 ? blocs.map((bloc, blocIndex) => (
            <Grid item xs={12} sm={6} md={4} key={blocIndex}>
              <Card
                sx={{ maxWidth: "100%", margin: "auto", boxShadow: 3, borderRadius: 2, mb: 4, p: 3 }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mb: 3,
                    p: 2,
                    borderBottom: "2px solid #E0E0E0",
                  }}
                >
                  <MDTypography variant="h4" fontWeight="bold" color="primary" sx={{ mb: 1 }}>
                    {bloc.numero_bloc}
                  </MDTypography>
                  <MDTypography variant="h6" fontStyle="italic" color="text">
                    {bloc.intitule_bloc}
                  </MDTypography>
                </MDBox>

                <MDBox sx={{ mt: 2 }}>
                  {bloc.data && Object.entries(bloc.data).map(([competence, { values, intitule }], index) => (
                    <MultiSegmentProgressBar
                      key={index}
                      values={values}
                      label={competence}
                      label_competence={intitule}
                      sx={{ mb: 2 }}
                    />
                  ))}
                </MDBox>
              </Card>
            </Grid>
          )) : (
            <Grid item xs={12}>
              <MDTypography variant="body1">Aucun bloc disponible.</MDTypography>
            </Grid>
          )}
        </Grid>
      </MDBox>

      {/* Graphique ApexChartCrux */}
      <Grid mt={1} item xs={12} ref={addToRefs}>
        <Card
          sx={{ width: "100%", margin: "auto", boxShadow: 3, borderRadius: 2, mb: 4, p: 3 }}
        >
          <MDBox sx={{ height: "100%" }}>
            <ApexChartCrux />
          </MDBox>
        </Card>
      </Grid>

      {/* Intégration de ApexChart pour "Évolution des points acquis" */}
      <MDBox mt={2} ref={addToRefs}>
        <Card sx={{ width: "100%", margin: "auto", boxShadow: 3, borderRadius: 2, p: 3 }}>
          <MDBox mb={2}>
            <MDTypography variant="h5" fontWeight="bold" color="primary">
              Évolution des points acquis
            </MDTypography>
          </MDBox>
          <ReactApexChart options={options} series={series} type="area" height={300} />
        </Card>
      </MDBox>

      {/* Ajoutez d'autres sections avec ref={addToRefs} si nécessaire */}

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
