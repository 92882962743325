import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ParcoursImages from "layouts/parcours/parcours-espace/parcours-page/components/ParcoursImages";
import ParcoursInfo from "layouts/parcours/parcours-espace/parcours-page/components/ParcoursInfo";
import ActiviteCard from "./components/ActiviteCard";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import ApexChart from "../parcours-grille/components/Apexchart";
import Header from "layouts/pages/profile/components/Header";
import { AuthContext } from "context";
import Divider from "@mui/material/Divider";
import useStore from "services/store";
import ApexChartBarTarget from "../parcours-grille/components/Apexchart_bar-target";

function ParcoursPage() {
  const userUid = useStore((state) => state.userId);
  const userInfo = useStore((state) => state.userInfo);
  const {selectedPromotion} = useStore((state) => ({selectedPromotion: state.selectedPromotion,}));
  const { id } = useParams();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [parcoursDetails, setParcoursDetails] = useState({
    identifiant_parcours: "",
    description_parcours: "",
    padlet_url: "",
  });
  const [cardData, setCardData] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [labels, setLabels] = useState([]);

  const selectedMatiere = useStore((state) => state.selectedMatiere);

  useEffect(() => {
    const fetchParcoursDetails = async () => {
      try {
        const data = await crudsServiceFAPI.getParcoursById(id);
        setParcoursDetails({
          identifiant_parcours: data.identifiant_parcours,
          description_parcours: data.description_parcours,
          padlet_url: data.padlet_url || "",
        });
      } catch (error) {
        console.error("Error fetching parcours details:", error);
      }
    };

    const fetchActivites = async () => {
      try {
        const activities = await crudsServiceFAPI.getActivitesByParcoursId(id);
        const activityLabels = [];

        const formattedActivities = activities.map((activity) => {
          activityLabels.push(activity.identifiant_activite);

          return {
            image: `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_activite}.jpg`,
            titre: activity.titre_activite,
            description: activity.description_activite,
            details: activity.detail_activite || "Détails non fournis",
            evaluation: activity.evaluation_activite || "Évaluation non fournie",
            livrables: activity.livrables_activite || "Livrables non fournis",
            todo: activity.todo_activite || "Tâches non fournies",
            identifiant_activite: activity.identifiant_activite,
            idMatiere: selectedMatiere ? selectedMatiere.identifiant_matiere.toString() : "",
            padlet_url: activity.padlet_url || null,
          };
        });

        setCardData(formattedActivities);
        setLabels(activityLabels);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchParcoursDetails();
    fetchActivites();
  }, [id, selectedMatiere]);

  const handleExpandCard = (identifiant_activite) => {
    setExpandedCardId(expandedCardId === identifiant_activite ? null : identifiant_activite);
  };

  const handleAddActivity = () => {
    navigate(`/parcours/parcours-gestion/activite-nouveau?identifiant_parcours=${id}`);
  };

  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    const fetchRatios = async () => {
      if (userUid && id) {
        try {
          const ratios = await crudsServiceFAPI.getRatiosByEleveParcour(userUid, id);
          setSeriesData(ratios);
        } catch (error) {
          console.error("Error fetching ratios:", error);
        }
      }
    };

    fetchRatios();
  }, [id, userUid]);

  const [seriesDataBar, setSeriesDataBar] = useState([]);

  useEffect(() => {
    const fetchRatiosActivites = async () => {
      if (userUid && selectedMatiere) {
        try {
          const data = await crudsServiceFAPI.getRatiosByEleveActivites(
            userUid,
            selectedMatiere.identifiant_matiere,
            id
          );
          setSeriesDataBar(data);
        } catch (error) {
          console.error("Error fetching ratios:", error);
        }
      } else if (selectedPromotion && selectedMatiere) {
        try {
          const data = await crudsServiceFAPI.getRatiosByPromotionActivites(
            selectedPromotion.identifiant_promotion,
            selectedMatiere.identifiant_matiere,
            id
          );
          setSeriesDataBar(data);
        } catch (error) {
          console.error("Error fetching ratios:", error);
        }
      }
    };

    fetchRatiosActivites();
  }, [userUid, selectedMatiere, id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* Première ligne avec le bouton Ajouter une activité */}
            <Grid item xs={12}>
              {ability.can("create", "activite") && (
                <MDButton variant="gradient" color="info" onClick={handleAddActivity}>
                  Ajouter une activité
                </MDButton>
              )}
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h5" fontWeight="medium" sx={{ mt: 2 }}>
                Détails du Parcours (ID parcours : {id})
              </MDTypography>
            </Grid>

            {/* Grille pour l'image et le graphique */}
            <Grid container spacing={3} alignItems="flex-start">
              {/* Colonne avec l'image */}
              <Grid item xs={12} lg={5} xl={5} sx={{ mt: 3 }}>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ml: 8,
                  }}
                >
                  <ParcoursImages identifiantParcours={id} />
                </MDBox>
              </Grid>

              {/* Divider vertical */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  ml: -1,
                  backgroundColor: "#0000FF",
                  width: "2px",
                  height: "100%",
                }}
              />

              {/* Colonne avec le graphique et les détails du parcours */}
              <Grid item xs={12} lg={6} xl={5} sx={{ mt: -10 }}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
                  {parcoursDetails.identifiant_parcours}
                </MDTypography>

                <ParcoursInfo
                  identifiant_parcours={id}
                  matiere={selectedMatiere?.identifiant_matiere}
                  navigate={navigate}
                />

                {/* Vous pouvez activer le graphique si nécessaire */}
                {/* <MDBox mt={1}>
                  <ApexChart series={seriesData} labels={labels} />
                </MDBox> */}
              </Grid>
            </Grid>

            {/* Deuxième ligne avec ApexChartBarTarget sur toute la largeur */}
            <Grid item xs={12}>
              <MDBox mt={3}>
                {seriesDataBar && Array.isArray(seriesDataBar) && seriesDataBar.length > 0 ? (
                  <ApexChartBarTarget seriesData={seriesDataBar} height={"auto"} />
                ) : (
                  <MDTypography variant="body1">Aucune donnée disponible.</MDTypography>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Header>

      <MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {cardData.map((card, index) => {
              const isExpanded = expandedCardId === card.identifiant_activite;
              return (
                <Grid
                  item
                  xs={12}
                  sm={isExpanded ? 12 : 6}
                  md={isExpanded ? 12 : 4}
                  key={index}
                >
                  <MDBox mt={3}>
                    <ActiviteCard
                      image={card.image}
                      title={card.titre}
                      description={card.description}
                      details={card.details}
                      evaluation={card.evaluation}
                      livrables={card.livrables}
                      todo={card.todo}
                      onExpand={() => handleExpandCard(card.identifiant_activite)}
                      expanded={isExpanded}
                      identifiant_activite={card.identifiant_activite}
                      identifiant_parcours={id}
                      idMatiere={card.idMatiere}
                      padlet_url={card.padlet_url}
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>

        {/* Ajout de l'iframe pour padlet_url sous les ActiviteCard */}
        {parcoursDetails.padlet_url && (
          <MDBox mt={4}>
            <MDTypography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>
              Padlet Embarqué
            </MDTypography>
            <div
              className="padlet-embed"
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "2px",
                boxSizing: "border-box",
                overflow: "hidden",
                position: "relative",
                width: "100%",
                background: "#F4F4F4",
              }}
            >
              <p style={{ padding: 0, margin: 0 }}>
                <iframe
                  src={parcoursDetails.padlet_url}
                  frameBorder="0"
                  allow="camera;microphone;geolocation"
                  style={{
                    width: "100%",
                    height: "608px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  title="Padlet Embedded"
                ></iframe>
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  margin: 0,
                  height: "28px",
                }}
              >
                <a
                  href="https://padlet.com?ref=embed"
                  style={{
                    display: "block",
                    flexGrow: 0,
                    margin: 0,
                    border: "none",
                    padding: 0,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://padlet.net/embeds/made_with_padlet_2022.png"
                      width="114"
                      height="28"
                      style={{
                        padding: 0,
                        margin: 0,
                        background: "0 0",
                        border: "none",
                        boxShadow: "none",
                      }}
                      alt="Made with Padlet"
                    />
                  </div>
                </a>
              </div>
            </div>
          </MDBox>
        )}
      </MDBox>

      <MDBox mt={5}>{/* Contenu intégré */}</MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ParcoursPage;
