import { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import MDBox from "components/MDBox";

function ParcoursImages({ identifiantParcours }) {
  const [currentImage, setCurrentImage] = useState("");
  const [imgsViewer, setImgsViewer] = useState(false);

  useEffect(() => {
    if (identifiantParcours) {
      const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/parcours/${identifiantParcours}.jpg`;
      setCurrentImage(imageUrl);
      console.log("ceramique", imageUrl)
    }
  }, [identifiantParcours]);

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);

  const handleImageError = () => {
    console.log("poulet")
    setCurrentImage(`${process.env.REACT_APP_FAPI_URL}/files/parcours/defaut.jpg`);
  };

  return (
    <MDBox>
      {currentImage && (
        <>
          <ImgsViewer
            imgs={[{ src: currentImage }]} 
            isOpen={imgsViewer}
            onClose={closeImgsViewer}
            currImg={0}
            backdropCloseable
          />

          <MDBox
            component="img"
            src={currentImage}
            alt="Parcours Image"
            shadow="lg"
            borderRadius="lg"
            width="70%"  // Réduction à 50% de la largeur
            height="auto" // Conserver la proportion de l'image
            onClick={openImgsViewer}
            onError={handleImageError} // Basculer vers l'image par défaut en cas d'erreur
          />
        </>
      )}
    </MDBox>
  );
}

export default ParcoursImages;
