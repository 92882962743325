import React, { useContext, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthContext, AuthContextProvider } from "context";
import { MaterialUIControllerProvider } from "context";
import { AbilityContext } from "Can";

// Fonction principale pour charger l'application avec AuthContext
function AppWithCallbackAfterRender() {
  const { ability, loading } = useContext(AuthContext); // Récupérer `ability` et `loading` directement depuis AuthContext
  const [isReady, setIsReady] = useState(false); // État local pour indiquer si l'app est prête

  // Utiliser `useEffect` pour surveiller le chargement
  useEffect(() => {
    if (!loading) {
      setIsReady(true); // Définir l'application comme prête une fois `loading` terminé
    }
  }, [loading]);

  if (!isReady) {
    return <div>Chargement des permissions...</div>; // Affichage temporaire pendant le chargement
  }

  return (
    <AbilityContext.Provider value={ability}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </AbilityContext.Provider>
  );
}

// Rendu de l'application
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AuthContextProvider> {/* Fournir AuthContext dès la racine */}
      <AppWithCallbackAfterRender />
    </AuthContextProvider>
  </BrowserRouter>
);
