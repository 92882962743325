import PropTypes from "prop-types"; // Importer PropTypes pour valider les props
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; // Importer le composant Card
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import { AbilityContext } from "Can"; // Importer le contexte Ability

function ParcoursInfo({ parcoursDetails, identifiantParcours }) {
  const ability = useContext(AbilityContext); // Récupérer l'objet `ability` depuis le contexte
console.log("gugule parcours infos : ", parcoursDetails, identifiantParcours)
  console.log("virgule1 : ", ability.can('read', 'Article')); // Affiche true si la permission est présente

  return (
    <MDBox>
      <MDBox mb={1}>
      </MDBox>

      <MDBox mt={2} mb={1} ml={0.5}> {/* Réduction de l'espacement */}
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description
        </MDTypography>
      </MDBox>

      <Card
  sx={{
    p: 2,
    mb: 2,
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    border: "1px solid #1976d2",
    borderRadius: "8px",
  }}
>
  <MDTypography
    variant="body2"
    color="text"
    fontWeight="regular"
    component="div"
    dangerouslySetInnerHTML={{ __html: parcoursDetails?.description_parcours }} // Ce contenu est passé comme `children`
  />
</Card>
    </MDBox>
  );
}

// Valider les props avec PropTypes
ParcoursInfo.propTypes = {
  parcoursDetails: PropTypes.object,
  identifiantParcours: PropTypes.string,
};

export default ParcoursInfo;
